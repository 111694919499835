import React from 'react';
import styles from '../ApplicationHeader.module.css';

function CareerApplicationBody({}){
	return(
		<div style={{padding:"15px",textAlign:"center",backgroundColor:"#f0f0f5"}}>
			<div className={styles.rowResponsive}>
				<div className={styles.columnResponsive15percent}>
					 <ul style={{listStyleType:"none"}}><li></li></ul>
				</div>
				<div className={styles.columnResponsive70percent}>
					<p style={{borderStyle:'solid',borderWidth:"1px",padding:"6px"}}>We have several position empty and ready to be filled. Check if 
					you fit on following positions then send email your CV to nileshadk@gmail.com</p><br/><br/>
					<p> THE POSITIONS ARE :- </p>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 1) Acquisition/Procurement  Manager </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 2) Supply Manager </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 3) Life cycle model Manager </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 4) Infrastructure Manager </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 5) Human Resource Manager </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 6) Quality Manager </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 7)  Knowledge Management Expert </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 8)  Portfolio Manager </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 9) Project Plan Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 10) Project Assessment and Control Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 11) Decision Management Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 12) Risk Management Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 13) Configuration Management Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 14) Information Management Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 15) Metrics Analysis Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 16)  Quality Assurance Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 17) Business Analysis Expert </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 18) Stakeholder Requirements Definition Expert </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 19) System Requirement Definition Expert </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 20) Architecture Definition Expert </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 21) Design Definition Expert </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 22) System Analysis Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 23) Implementation Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 24) Integration Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 25) Verification Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 26) Validation Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 27) Transition Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 28) Operation Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:"left"}}>
						<p> 29) Maintainence Engineer </p>
					</div><br/>
					<div style={{padding:"12px",borderStyle:'solid',borderWidth:"1px",textAlign:'left'}}>
						<p> 30) Disposal Engineer </p>
					</div><br/>
				</div>
				<div className={styles.columnResponsive15percent}>
					 <ul style={{listStyleType:"none"}}><li></li></ul>
				</div>
			</div>
		</div>
	);
}
export default CareerApplicationBody