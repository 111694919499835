import React from 'react';
import styles from '../ApplicationHeader.module.css';

function AboutApplicationBody({}){
	return(
		<div style={{padding:"15px",textAlign:"center",backgroundColor:"#f0f0f5"}}>
			<div className={styles.rowResponsive}>
				<div className={styles.columnResponsive15percent}>
					<ul style={{listStyleType:"none"}}><li> </li></ul>
				</div>
				<div className={styles.columnResponsive70percent} style={{borderWidth:"1px",borderStyle:"solid",backgroundColor:"white"}}>
					<br/>
				</div>
				<div className={styles.columnResponsive15percent}>
					<ul style={{listStyleType:"none"}}><li> </li></ul>
				</div>
			</div>
		</div>
	)
}
export default AboutApplicationBody