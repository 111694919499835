import React from 'react';

import { useState,useEffect } from 'react';

function HomeApplicationBody({HomeApplicationBody}){
	//const[HomeApplicationBody,setHomeApplicationBody]=useState(HomeApplicationBody);
	const[fontSize,setFontSize]=useState("");
	const[smallFontSize,setSmallFontSize]=useState("");
	const[listItemStartCounter,setListItemStartCounter]=useState(0);
	const[listItemStopCounter,setListItemStopCounter]=useState(0);
	const[showNavigationButtons,setShowNavigationButtons]=useState("none");

	useEffect(()=>{
		renderAppScreen();
	   	window.addEventListener("resize",renderAppScreen);
	},[]);

	function renderAppScreen(){
		var valuething = getScreenCalculation(80,80,window.innerWidth);
		//alert(valuething)
		if((valuething>=360)&&(valuething<=540)){
			alert("came to small screen")
			setFontSize("23px");
			setSmallFontSize("12px");
			setListItemStartCounter(1);
			setListItemStopCounter(2);	
		}else if((valuething>=541)&&(valuething<=720)){
			alert("came to medium screen")
	    	setFontSize("23px");
			setSmallFontSize("15px");
			setListItemStartCounter(1);
			setListItemStopCounter(3);
	    	//this.setState({fontSize:"23px",smallFontSize:"15px",listItemStartCounter:1,listItemStopCounter:3})
	    }else if((valuething>=721)){
	    	alert("came to big screen")
	    	setFontSize("23px");
			setSmallFontSize("17px");
			setListItemStartCounter(1);
			setListItemStopCounter(4);
			setShowNavigationButtons("block");
	    	// this.setState({fontSize:"23px",smallFontSize:"17px",listItemStartCounter:1,listItemStopCounter:4})
	    	// this.setState({showNavigationButtons:"block"});
	    // }else if((window.innerWidth>='769')&&(window.innerWidth<='1024')){
	    // 	this.setState({fontSize:"21px",smallFontSize:"20px",displayType:"small-desktop",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"quad-not-full",listItemStartCounter:1,listItemStopCounter:5})
	    // 	this.setState({showNavigationButtons:"block"});
	    // }else if((window.innerWidth>='1025')&&(window.innerWidth<='1200')){
	    // 	this.setState({fontSize:"24px",smallFontSize:"22px",displayType:"medium-desktop",footerPaddingBottom:"37px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
	    // 	this.setState({showNavigationButtons:"none"});
	    // }else if((window.innerWidth>='1201')&&((window.innerWidth<='1680'))){
	    // 	this.setState({fontSize:"25px",smallFontSize:"24px",displayType:"large-desktop",footerPaddingBottom:"38px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
	    // 	this.setState({showNavigationButtons:"none"});
	    // }else if((window.innerWidth>='1681')&&(window.innerWidth<='2201')){
	    // 	this.setState({fontSize:"25px",smallFontSize:"24px",displayType:"large-desktop",footerPaddingBottom:"39px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
	    // 	this.setState({showNavigationButtons:"none"});
	    }
	    else{}
	}
	function getScreenCalculation(percentage1,percentage2,value){
		var temporaryPercentValue = percentage1/100*value;
		var finalPercentValue = percentage2/100*temporaryPercentValue;
		return finalPercentValue
	}
	return(
		<div>
			<h1 style={{fontSize:`${HomeApplicationBody.mainTopicTextSize}`}}>OUR PORTFOLIO</h1><br/><br/>
			<div className="w3-row">
				<div className="w3-col" style={{width:"10%"}}><p></p></div>
			    <div className="w3-col" style={{width:"80%",borderStyle:"solid",borderWidth:"1px"}}>
			    	<div className="w3-row" style={{paddingTop:"3px",paddingBottom:"3px"}}>
		              {/*this.renderTeamCards()*/}
		              
		              <div className="w3-col" style={{width:"10%"}}>
			              <p>
			              	<span style={{display:"inline",fontSize:'23px'}} onClick={()=>{console.log("left clicked")}}>⬅</span>
			              </p>
		              </div>
		              <div className="w3-col" style={{width:"80%",borderStyle:"solid",borderWidth:"1px"}}>
			              <RenderOurPortfolio listItemStartCounter={listItemStartCounter} listItemStopCounter={listItemStopCounter} smallFontSize={smallFontSize}/>
			          </div>
		              <div className="w3-col" style={{width:"10%"}}>
		              	<p>
							<span style={{display:"inline",fontSize:'23px'}} onClick={()=>{console.log("right clicked")}}>➡</span>	              
		              	</p>
		              </div>
		            </div>
		            <div className="w3-row">
		            	<div className="w3-col" style={{width:"10%"}}><p></p></div>
		            	<div className="w3-col" style={{width:"80%",borderStyle:"solid",borderWidth:"1px"}}><p></p></div>
		            	<div className="w3-col" style={{width:"10%"}}><p></p></div>
		            </div>
		            <br/><br/><br/><br/><br/>
			    </div>
			    <div className="w3-col" style={{width:"10%"}}><p></p></div>
			</div>
			
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
			<h1>This is Home</h1>
		</div>
	)

}

// class HomeApplicationBody extends React.Component{
// 	constructor(props){
// 		super(props);
// 		this.state={
// 			HomeApplicationBody:this.props.HomeApplicationBody
// 		}
// 		console.log(this.state.HomeApplicationBody.mainTopicTextSize)

// 	}
// 	shouldComponentUpdate(){
// 		return true
// 	}
// 	componentWillMount(){
// 	   this.renderAppScreen();
// 	   window.addEventListener("resize",this.renderAppScreen);
// 	}
// 	componentWillUmmount(){
// 	   window.removeEventListener("resize",this.renderAppScreen); 
// 	}
// 	componentDidUpdate(prevProps){
// 		if(prevProps.HomeApplicationBody!=this.props.HomeApplicationBody){
// 			this.setState({HomeApplicationBody:this.props.HomeApplicationBody})
// 		}
// 		console.log(this.props.HomeApplicationBody.mainTopicTextSize)
// 	}
// 	renderAppScreen=()=>{
// 		var valuething = this.getScreenCalculation(80,80,window.innerWidth);
// 		if((valuething>='360')&&(valuething<='540')){
// 			this.setState({fontSize:"23px",smallFontSize:"12px",listItemStartCounter:1,listItemStopCounter:2});
// 			//this.setState({showNavigationButtons:"block"});
// 		}else if((valuething>='541')&&(valuething<='720')){
// 	    	this.setState({fontSize:"23px",smallFontSize:"15px",listItemStartCounter:1,listItemStopCounter:3})
// 	    	//this.setState({showNavigationButtons:"block"});
// 	    }else if((valuething>='721')){
// 	    	this.setState({fontSize:"23px",smallFontSize:"17px",listItemStartCounter:1,listItemStopCounter:4})
// 	    	this.setState({showNavigationButtons:"block"});
// 	    // }else if((window.innerWidth>='769')&&(window.innerWidth<='1024')){
// 	    // 	this.setState({fontSize:"21px",smallFontSize:"20px",displayType:"small-desktop",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"quad-not-full",listItemStartCounter:1,listItemStopCounter:5})
// 	    // 	this.setState({showNavigationButtons:"block"});
// 	    // }else if((window.innerWidth>='1025')&&(window.innerWidth<='1200')){
// 	    // 	this.setState({fontSize:"24px",smallFontSize:"22px",displayType:"medium-desktop",footerPaddingBottom:"37px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
// 	    // 	this.setState({showNavigationButtons:"none"});
// 	    // }else if((window.innerWidth>='1201')&&((window.innerWidth<='1680'))){
// 	    // 	this.setState({fontSize:"25px",smallFontSize:"24px",displayType:"large-desktop",footerPaddingBottom:"38px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
// 	    // 	this.setState({showNavigationButtons:"none"});
// 	    // }else if((window.innerWidth>='1681')&&(window.innerWidth<='2201')){
// 	    // 	this.setState({fontSize:"25px",smallFontSize:"24px",displayType:"large-desktop",footerPaddingBottom:"39px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
// 	    // 	this.setState({showNavigationButtons:"none"});
// 	    }
// 	    else{}
// 	}
// 	getScreenCalculation=(percentage1,percentage2,value)=>{
// 		var temporaryPercentValue = percentage1/100*value;
// 		var finalPercentValue = percentage2/100*temporaryPercentValue;
// 		return finalPercentValue
// 	}
//   	renderOurPortfolio(){
//   		return(
//   			<div>
//   				<div className="w3-col" style={{width:`${this.state.cardWidth}`,padding:'10px'}}>
//   					<p>Option 1</p>
//   				</div>
//   				<div className="w3-col" style={{width:`${this.state.cardWidth}`,padding:'10px'}}>
//   					<p>Option 2</p>
//   				</div>
//   				<div className="w3-col" style={{width:`${this.state.cardWidth}`,padding:'10px'}}>
//   					<p>Option 3</p>
//   				</div>
//   				<div className="w3-col" style={{width:`${this.state.cardWidth}`,padding:'10px'}}>
//   					<p>Option 4</p>
//   				</div>
//   				<div className="w3-col" style={{width:`${this.state.cardWidth}`,padding:'10px'}}>
//   					<p>Option 5</p>
//   				</div>
//   				<div className="w3-col" style={{width:`${this.state.cardWidth}`,padding:'10px'}}>
//   					<p>Option 6</p>
//   				</div>

//   			</div>
//   		);
//   	}
// 	render(){
// 		return(
// 			<div>
// 				<h1 style={{fontSize:`${this.state.HomeApplicationBody.mainTopicTextSize}`}}>OUR PORTFOLIO</h1><br/><br/>
// 				<div className="w3-row">
// 					<div className="w3-col" style={{width:"10%"}}><p></p></div>
// 				    <div className="w3-col" style={{width:"80%",borderStyle:"solid",borderWidth:"1px"}}>
// 				    	<div className="w3-row" style={{paddingTop:"3px",paddingBottom:"3px"}}>
// 			              {/*this.renderTeamCards()*/}
			              
// 			              <div className="w3-col" style={{width:"10%"}}>
// 				              <p>
// 				              	<span style={{display:"inline",fontSize:'23px'}} onClick={()=>{console.log("left clicked")}}>⬅</span>
// 				              </p>
// 			              </div>
// 			              <div className="w3-col" style={{width:"80%",borderStyle:"solid",borderWidth:"1px"}}>
// 				              <RenderOurPortfolio listItemStartCounter={this.state.listItemStartCounter} listItemStopCounter={this.state.listItemStopCounter} smallFontSize={this.state.smallFontSize}/>
// 				          </div>
// 			              <div className="w3-col" style={{width:"10%"}}>
// 			              	<p>
// 								<span style={{display:"inline",fontSize:'23px'}} onClick={()=>{console.log("left clicked")}}>➡</span>	              
// 			              	</p>
// 			              </div>
// 			            </div>
// 			            <div className="w3-row">
// 			            	<div className="w3-col" style={{width:"10%"}}><p></p></div>
// 			            	<div className="w3-col" style={{width:"80%",borderStyle:"solid",borderWidth:"1px"}}><p></p></div>
// 			            	<div className="w3-col" style={{width:"10%"}}><p></p></div>
// 			            </div>
// 				    </div>
// 				    <div className="w3-col" style={{width:"10%"}}><p></p></div>
// 				</div>
				
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 				<h1>This is Home</h1>
// 			</div>
// 		)
// 	}
// }




function RenderOurPortfolio(props){
	console.log("start counter is "+props.listItemStartCounter);
	console.log("stop counter is "+props.listItemStopCounter);
	if((props.listItemStartCounter==1)&&(props.listItemStopCounter==2)){
		return(
			<div className="w3-row">
				<div className="w3-col" style={{width:'50%',padding:"5px",textAlign:"center"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Restaurant Management System</span>
				</div>
				{/*<div className="w3-col" style={{width:'50%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Jury Law Firm</span>
				</div>*/}
				<div className="w3-col" style={{width:'50%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}> </span>
				</div>
			</div>
		);
	}else if((props.listItemStartCounter==2)&&(props.listItemStopCounster==3)){
		return(
			<div className="w3-row">
				<div className="w3-col" style={{width:'50%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Jury Law Firm</span>
				</div>
				<div className="w3-col" style={{width:'50%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}> </span>
				</div>
				{/*<div className="w3-col" style={{width:'50%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Journey Journal</span>
				</div>*/}

			</div>
		);
	}
	else if((props.listItemStartCounter==3)&&(props.listItemStopCounter==4)){
		return(
			<div className="w3-row">
				<div className="w3-col" style={{width:'50%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Mcq</span>
				</div>
				<div className="w3-col" style={{width:'50%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}> </span>
				</div>
				{/*<div className="w3-col" style={{width:'50%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}> </span>
				</div>*/}
			</div>
		);
	}
	else if((props.listItemStartCounter==1)&&(props.listItemStopCounter==3)){
		return(
			<div className="w3-row">
				<div className="w3-col" style={{width:'33%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Restaurant Management System</span>
				</div>
				<div className="w3-col" style={{width:'33%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Jury Law Firm</span>
				</div>
				{/*<div className="w3-col" style={{width:'33%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}> </span>
				</div>*/}
			</div>
		);
	}
	else if((props.listItemStartCounter==2)&&(props.listItemStopCounter==4)){
		return(
			<div className="w3-row">
				<div className="w3-col" style={{width:'33%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Jury Law Firm</span>
				</div>
				<div className="w3-col" style={{width:'33%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Journey Journal</span>
				</div>
				<div className="w3-col" style={{width:'33%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}> </span>
				</div>
			</div>
		);
	}
	else if((props.listItemStartCounter==1)&&(props.listItemStopCounter==4)){
		return(
			<div className="w3-row">
				<div className="w3-col" style={{width:'25%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Restaurant Management System</span>
				</div>
				<div className="w3-col" style={{width:'25%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Jury Law Firm</span>
				</div>
				<div className="w3-col" style={{width:'25%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}>Journey Journal</span>
				</div>
				<div className="w3-col" style={{width:'25%',padding:"5px"}}>
					<span style={{textAlign:"center",fontSize:`${props.smallFontSize}`}}> </span>
				</div>
			</div>
		);
	}else{
		return null;
	}
}
export default HomeApplicationBody
