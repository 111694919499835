import React from 'react';
import { useState,useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import ApplicationHeader from './components/ApplicationHeader.js';
import ApplicationFooter from './components/ApplicationFooter.js';
import ApplicationBody from './components/ApplicationBody.js';
import ImageClose from './components/close.png';
import ImageLogo from './components/repomeanLogo.jpg';


function App() {
  return (
    /**<div className="App">
          Learn React 
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer">
        </a>
      </header>
    </div>**/
    <div className="App">
      <ReactAppMainScreen />
    </div>
  );
}

function ReactAppMainScreen(){
  const[fullScreenedMainApp,setFullScreenedMainApp]=useState(false);
  const[currentPage,setCurrentPage]=useState("HOME");
  const[topOfThePage,setTopOfThePage]=useState(true);
  const[mobileApp,setMobileApp]=useState(false);
  const[marginTopOfBody,setMarginTopOfBody]=useState("0px");
  const[homeApplicationBody,setHomeApplicationBody]=useState({
        mainTopicTextSize:"2em"
  });
  useEffect(()=>{
    renderAppCssBreakpoints();
    detectTopOfPage();
    window.addEventListener("scroll",detectTopOfPage);
    window.addEventListener("resize",renderAppCssBreakpoints);
    return () => {
      //componentWillUnmount works here
      window.removeEventListener("scroll",detectTopOfPage);
      window.removeEventListener("resize",renderAppCssBreakpoints);
    }
  },[]);
  function detectTopOfPage(){
    if((window.scrollX<=0)&&(window.scrollY<=0)){
      setTopOfThePage(true);
    }else{
      setTopOfThePage(false);
    }
  }
  function renderAppCssBreakpoints(){
    if((window.innerWidth>='320')&&(window.innerWidth<='480')){
      setMobileApp(true);
      setMarginTopOfBody("60px");
      setHomeApplicationBody({...homeApplicationBody,
        mainTopicTextSize:"1.5em"
      });
    }
    else if((window.innerWidth>='481')&&(window.innerWidth<='570')){
      setMobileApp(true);
      setMarginTopOfBody("60px");
      setHomeApplicationBody({...homeApplicationBody,
        mainTopicTextSize:"2em"
      });
    }
    else if((window.innerWidth>='571')&&(window.innerWidth<='768')){
      setMobileApp(true);
      setMarginTopOfBody("60px");
      setHomeApplicationBody({...homeApplicationBody,
        mainTopicTextSize:"3em"
      });
    }
    else if((window.innerWidth>='769')&&(window.innerWidth<='1024')){
      setMobileApp(false);
      setMarginTopOfBody("60px");
      setHomeApplicationBody({...homeApplicationBody,
        mainTopicTextSize:"4em"
      });
    }
    else if((window.innerWidth>='1025')&&(window.innerWidth<='1200')){
      setMobileApp(false);
      setMarginTopOfBody("60px");
      setHomeApplicationBody({...homeApplicationBody,
        mainTopicTextSize:"5em"
      });
    }
    else if((window.innerWidth>='1201')){
      setMobileApp(false);
      setMarginTopOfBody("60px");
      setHomeApplicationBody({...homeApplicationBody,
        mainTopicTextSize:"6em"
      });
    }  
  }
  function updateFullScreenedMainApp(){
    setFullScreenedMainApp(!fullScreenedMainApp);
    if(window.scrollY==0){
      window.scrollTo(0,0);
    }
  }
  function updateCurrentPage(value){
    alert(value);
    setCurrentPage(value);
  }
  return(
    <DecideFullScreenOrNormal HomeApplicationBody={homeApplicationBody} mobileApp={mobileApp} marginTopOfBody={marginTopOfBody} updateCurrentPage={updateCurrentPage} currentPage={currentPage} fullScreenedMainApp={fullScreenedMainApp} updateFullScreenedMainApp={updateFullScreenedMainApp} topOfThePage={topOfThePage}/>
  );
}


function DecideFullScreenOrNormal(props){
  if(!props.fullScreenedMainApp){
    return <ReactAppNormal HomeApplicationBody={props.HomeApplicationBody} mobileApp={props.mobileApp} marginTopOfBody={props.marginTopOfBody} currentPage={props.currentPage} updateCurrentPage={props.updateCurrentPage} fullScreenedMainApp={props.fullScreenedMainApp} updateFullScreenedMainApp={props.updateFullScreenedMainApp} topOfThePage={props.topOfThePage}/>;
  }else if(props.fullScreenedMainApp){
    return <ReactAppMobileMenu currentPage={props.currentPage} updateCurrentPage={props.updateCurrentPage} fullScreenedMainApp={props.fullScreenedMainApp} updateFullScreenedMainApp={props.updateFullScreenedMainApp}/>;
  }  
}

function ReactAppNormal({HomeApplicationBody,mobileApp,marginTopOfBody,currentPage,updateCurrentPage,fullScreenedMainApp,updateFullScreenedMainApp,topOfThePage}){
  return(
    <div>
      <ApplicationHeader currentPage={currentPage} updateCurrentPage={updateCurrentPage} fullScreenedMainApp={fullScreenedMainApp} updateFullScreenedMainApp={updateFullScreenedMainApp} topOfThePage={topOfThePage} />
      <ApplicationBody HomeApplicationBody={HomeApplicationBody} mobileApp={mobileApp} marginTopOfBody={marginTopOfBody} currentPage={currentPage} updateCurrentPage={updateCurrentPage} topOfThePage={topOfThePage} />
      <ApplicationFooter />
    </div>
  );
}


// class ReactAppNormal extends React.Component{
//   constructor(props){
//     super(props);
//     this.state={fullScreenedMainApp:this.props.fullScreenedMainApp}
//   }
//   render(){
//     return(
//       <div>
//         <ApplicationHeader currentPage={this.props.currentPage} updateCurrentPage={this.props.updateCurrentPage} fullScreenedMainApp={this.state.fullScreenedMainApp} updateFullScreenedMainApp={this.props.updateFullScreenedMainApp} topOfThePage={this.props.topOfThePage}/>
//         <ApplicationBody HomeApplicationBody={this.props.HomeApplicationBody} mobileApp={this.props.mobileApp} marginTopOfBody={this.props.marginTopOfBody} currentPage={this.props.currentPage} updateCurrentPage={this.props.updateCurrentPage} topOfThePage={this.props.topOfThePage}/>
//         <ApplicationFooter/>
//       </div>
//     )
//   }
// }

function ReactAppMobileMenu({currentPage,updateCurrentPage,fullScreenedMainApp,updateFullScreenedMainApp}){
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);
  return(
    <div>
      <div>
        <img src={ImageClose} onClick={()=>{updateFullScreenedMainApp()}} height="50" width="50" style={{float:"right",clear:"both",paddingRight:7,paddingTop:3}}/>
      </div><br/><br/><br/>
      <hr color="#f0f0f5" /> <hr color="#f0f0f5" />
      <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}} onClick={()=>{updateCurrentPage("HOME"); updateFullScreenedMainApp();}}>HOME</div>
      <hr color="#f0f0f5" /> <hr color="#f0f0f5" />
      <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}} onClick={()=>{updateCurrentPage("SERVICES"); updateFullScreenedMainApp();}}>SERVICES</div>
      <hr color="#f0f0f5" /> <hr color="#f0f0f5" />
      <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}} onClick={()=>{updateCurrentPage("NEWS"); updateFullScreenedMainApp();}}>NEWS</div>
      <hr color="#f0f0f5" /> <hr color="#f0f0f5" />
      <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}} onClick={()=>{updateCurrentPage("CAREER"); updateFullScreenedMainApp();}}>CAREER</div>
      <hr color="#f0f0f5" /> <hr color="#f0f0f5" />
      <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}} onClick={()=>{updateCurrentPage("CONTACTS"); updateFullScreenedMainApp();}}>CONTACTS</div>
      <hr color="#f0f0f5" /> <hr color="#f0f0f5" />
      <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}} onClick={()=>{updateCurrentPage("ABOUT"); updateFullScreenedMainApp();}}>ABOUT</div>
      <hr color="#f0f0f5" /> <hr color="#f0f0f5" />
      <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}}>Social medias</div>
      <hr color="#f0f0f5" /> <hr color="#f0f0f5" /> <hr color="#f0f0f5" /> <hr color="#f0f0f5" />
        <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}}>Powered by : </div><br/>
        <div>
          <img src={ImageLogo} height="40" width="70"/>
        </div>
        <div style={{fontSize:"29px",textAlign:"center",margin:"19px"}}>REPOMEAN LABS</div><br/>
      <hr color="#f0f0f5"/> <hr color="#f0f0f5"/> <hr color="#f0f0f5"/> <hr color="#f0f0f5"/>  
    </div>
  )
}
function ReactAppDuringTopScreen(){
  return(
    <div>hehe</div>
  );
}


// class ReactAppMobileMenu extends React.Component{
//   constructor(props){
//     super(props);
//     this.state={fullScreenedMainApp:this.props.fullScreenedMainApp}
//   }
//   componentDidMount(){
//     window.scrollTo(0, 0);
//   }
//   render(){
    
//   }
// }


// class ReactAppDuringTopScreen extends React.Component{
//   render(){
    
//   }
// }
export default App;