import React from 'react';
import styles from '../ApplicationHeader.module.css';
import ComputerCode from '../computerCode.png';
import PrototypeDevelopment from '../prototype.png';
import PriceQuotation from '../price-quotation.jpg';


function ServicesApplicationBody({}){
	return(
		<div style={{padding:"15px",textAlign:"center",backgroundColor:"#f0f0f5"}}>
			<div className={styles.rowResponsive}>
				<div className={styles.columnResponsive15percent}>
					 <ul style={{listStyleType:"none"}}><li></li></ul>
				</div>
				<div className={styles.columnResponsive70percent}>
					<p style={{borderWidth:"1px",borderStyle:"solid",padding:"5px",textAlign:"justify"}}>
					Repomean is a software research / development company. We perform all sorts of software
					development processes and procedures. Our primary service is to serve the client's request 
					in the most effienct, proficient and diligently professional manner. But still, our services 
					can be broken down into few points below, though this is a curated list of what we actually do 
					at Repomean  and do not contain crucial paradigms such as 
					Devops or CI/CD etc. that need to be maintained for long. Our services are only limited by client's demand. Dont forget to message us with your questions.</p><br/>

					<div style={{width:"100%"}}>
						<p style={{textAlign:"left",fontWeight:"bold"}}>1) Research/Development</p>
						<img src={ComputerCode} alt="image of research and development" style={{width:"100%",height:"50%"}} loading="lazy"/>
						<p style={{padding:"5px",textAlign:"justify"}}>Research/Development is the set of activities 
						performed in creating new services or products 
						and improving existing ones. It is the first stage 
						of building of a potential new service or the production process. At Repomean Labs, 
						we take this crucial aspect of development and provide it as a service that we offer
						to our customers. Not to mention our project are supported and maintained as per the
						customer demands.</p>
					</div>
					<br/>
					<div style={{width:"100%"}}>
						<p style={{textAlign:"left",fontWeight:"bold"}}>2) Prototype development</p>
						<img src={PrototypeDevelopment} alt="image of research and development" style={{width:"100%",height:"50%"}} loading="lazy"/>
						<p style={{padding:"5px",textAlign:"justify"}}>A prototype is an early sample, model, or release of a product built to test a concept or process.
						Prototype development is the act of achieving this preliminary milestone and developing a prototype or simply MVP. At Repomean Labs, all your
						prototype needs are serviced professionally. Ranging from simple new websites to custom AI implementation, your next
						prototype is only a request away. Not only this, but your prototypes can even be transformed to consumer enterprises in no time. Of course, this is as per your demand. </p>
					</div>
					<br/>
					<div style={{width:"100%"}}>
						<p style={{textAlign:"left",fontWeight:"bold"}}>3) Price Quotation</p>
						<img src={PriceQuotation} alt="image of research and development" style={{width:"100%",height:"50%"}} loading="lazy"/>
						<p style={{padding:"5px",textAlign:"justify"}}>Help us help you bring your next product home. Start by allocating money and finish
						by getting a complete product. As always costumers are in charge here. You just tell us the price quotation and technology you desire
						and we will take care of the rest. Fast consulation, specification analysis, Design/development are but few stitches 
						you get before the final dosage. Tell us your budget and forget the rest. Price Quotation is a service we provide
						primarily to satisfy our customers as the first deal between us and you is the final deal between us and you.</p>
					</div>
					<br/>
				</div>
				<div className={styles.columnResponsive15percent}>
					 <ul style={{listStyleType:"none"}}><li></li></ul>
				</div>
			</div>
		</div>
	)
}
export default ServicesApplicationBody