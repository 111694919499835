import React from 'react';
import styles from '../ApplicationHeader.module.css';

function ContactsApplicationBody({}){
	return(
		<div style={{padding:"15px",textAlign:"center",backgroundColor:"#f0f0f5"}}>
			<br/>
			<div className={styles.rowResponsive}>
				<div className={styles.columnResponsive15percent}>
					<ul style={{listStyleType:"none"}}><li> </li></ul>
				</div>
				<div className={styles.columnResponsive70percent} style={{borderWidth:"1px",borderStyle:"solid",padding:"5px",textAlign:"justify"}}>
					<p> We are a client-centric company. Our company contacts and CEO contacts are available 24/7. So feel 
					free to contact us anytime. There is also a provision to send important email below. Please send email 
					through the form below and we will respond right away. </p>
				</div>
				<div className={styles.columnResponsive15percent}>
					<ul style={{listStyleType:"none"}}><li> </li></ul>
				</div>
			</div>
			<div className={styles.rowResponsive}>
				<div className={styles.columnResponsive15percent}>
					<ul style={{listStyleType:"none"}}><li> </li></ul>
				</div>
				<div>
					<div className={styles.columnResponsive} >
						<h2>Company</h2>
						<p>Repomean Private Limited</p>
						<p>Kathmandu, Nepal</p>
						<p>+977014267634</p>
						<p>Email:meanrepo@gmail.com</p>
					</div>
					
					<div className={styles.columnResponsive} >
						<h2>CEO</h2>
						<p>Nilesh Adhikari</p>
						<p>Kathmandu, Nepal</p>
						<p>+9779810354631</p>
						<p>Email:nileshadk@gmail.com</p>
					</div>
				</div>
				<div className={styles.columnResponsive15percent}>
					<ul style={{listStyleType:"none"}}><li> </li></ul>
				</div>
			</div><br/>
			<div className={styles.rowResponsive}>
				<div className={styles.columnResponsive15percent}>
					<ul style={{listStyleType:"none"}}><li> </li></ul>
				</div>
				<div className={styles.columnResponsive70percent} style={{borderWidth:"1px",borderStyle:"solid"}}>
					<h2>Send us email</h2>
					<form>
						<input type="name" style={{height:"25px",width:"70%",margin:"0 auto",fontSize:"16px",borderStyle:"solid",borderWidth:"1px"}} defaultValue="Enter name" autoComplete="off"/><br/><br/>
						<input type="email" style={{height:"25px",width:"70%",margin:"0 auto",fontSize:"16px",borderStyle:"solid",borderWidth:"1px"}} defaultValue="Enter email address" autoComplete="off"/><br/><br/>
						<select id="services" name="topic"  style={{height:"25px",width:"72%",margin:"0 auto",fontSize:"16px",borderStyle:"solid",borderWidth:"1px"}}>
						  <option value="select">SELECT A TOPIC</option>
						  <option value="R&D">Research and Development</option>
						  <option value="PriceQuotation">Price Quotation</option>
						  <option value="prototype">Prototype Development</option>
						</select><br/><br/>
						<textarea name="message" style={{ width:"70%",margin:"0 auto",fontSize:"16px",borderStyle:"solid",borderWidth:"1px"}} defaultValue="Enter message here" autoComplete="off" /><br/><br/>
						<button style={{height:"25px",width:"72%",margin:"0 auto",fontSize:"16px",borderStyle:"solid",borderWidth:"1px"}}>Submit message </button>
					</form><br/>
				</div>
				<div className={styles.columnResponsive15percent}>
					<ul style={{listStyleType:"none"}}><li> </li></ul>
				</div>
			</div>
		</div>
	)
}

export default ContactsApplicationBody