import React from 'react';

function NewsApplicationBody({}){
	return(
		<div style={{marginTop:'65px'}}>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
			<h1>This is news</h1>
		</div>
	)
}
export default NewsApplicationBody