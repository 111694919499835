import React from 'react';
import { useState,useEffect } from 'react';
import styles from './ApplicationHeader.module.css';
import ImageLogo from './repomeanLogo.jpg';
import ImageMenu from './menu.png';
import TopOfPageImageBackground from './topOfPageImageBackground.jpg';

function ApplicationHeader({currentPage, updateCurrentPage, fullScreenedMainApp, updateFullScreenedMainApp, topOfThePage}){
	const[widthOfHeaderTitle,setWidthOfHeaderTitle]=useState("");
	const[widthOfHeaderMenu,setWidthOfHeaderMenu]=useState("");
	const[fontSize,setFontSize]=useState("");
	const[displayType,setDisplayType]=useState("");
	const[currentPageState,setCurrentPageState]=useState(currentPage);
	const[menuOpened,setMenuOpened]=useState(fullScreenedMainApp);
	const[displayCompanyName,setDisplayCompanyName]=useState("inline");
	const[menuIconHeight,setMenuIconHeight]=useState(0);
	const[menuIconWidth,setMenuIconWidth]=useState(0);
	const[menuTitlePaddingTop,setMenuTitlePaddingTop]=useState(0);
	const[menuTitleMarginLeft,setMenuTitleMarginLeft]=useState(0);
	const[titlePaddingTop,setTitlePaddingTop]=useState(0);
	const[titlePaddingLeft,setTitlePaddingLeft]=useState(0);
	const[menuPaddingLeft,setMenuPaddingLeft]=useState(0);
	const[currentWidth,setCurrentWidth]=useState(0);
	const[topOfThePageState,setTopOfThePageState]=useState(0);
	
	// function updateCurrentPage(page){
	// 	setCurrentPageState(page);
	// }
	function updateMenuOpened(){
		setMenuOpened(!menuOpened);
		updateFullScreenedMainApp();
	}
	function detectTopOfPage(){
		if((window.scrollX<=0)&&(window.scrollY<=0)){
			setTopOfThePageState(true);
		}else{
			setTopOfThePageState(false);
		}
	}
	useEffect(()=>{
		renderAppCssBreakpoints();
		window.addEventListener("resize",renderAppCssBreakpoints);
		window.addEventListener("scroll",detectTopOfPage);
		return ()=>{
			window.removeEventListener("resize",renderAppCssBreakpoints);
			window.removeEventListener("scroll",detectTopOfPage);
		}
	},[]);

	function renderAppCssBreakpoints(){
		//alert("came here too");
		// var temporaryWidthOfHeaderTitle=(parseInt((35/100)*(window.innerWidth))+"px")
		// var temporaryWidthOfHeaderMenu=(parseInt((65/100)*(window.innerWidth))+"px")
		if((window.innerWidth>='320')&&(window.innerWidth<='480')){
			var temporaryWidthOfHeaderTitle=(parseInt((70/100)*(window.innerWidth))+"px")
			var temporaryWidthOfHeaderMenu=(parseInt((30/100)*(window.innerWidth))+"px")
			setFontSize("23px");
			setDisplayType("mobile");
			setDisplayCompanyName("none");
			setWidthOfHeaderTitle(temporaryWidthOfHeaderTitle);
			setWidthOfHeaderMenu(temporaryWidthOfHeaderMenu);
			setMenuIconHeight(35);
			setMenuIconWidth(35);
			setMenuTitlePaddingTop(4);
			setMenuTitleMarginLeft(14);
			setTitlePaddingTop(3);
			setTitlePaddingLeft(14);
			setMenuPaddingLeft(1);
			setCurrentWidth(window.innerWidth);
	    }else if((window.innerWidth>='481')&&(window.innerWidth<='570')){
	    	//this.setState({fontSize:"18px",displayType:"tablet"})
	    	var temporaryWidthOfHeaderTitle=(parseInt((45/100)*(window.innerWidth))+"px");
			var temporaryWidthOfHeaderMenu=(parseInt((55/100)*(window.innerWidth))+"px");
	    	setFontSize("23px");
			setDisplayType("tablet");
			setDisplayCompanyName("none");
			setWidthOfHeaderTitle(temporaryWidthOfHeaderTitle);
			setWidthOfHeaderMenu(temporaryWidthOfHeaderMenu);
			setMenuIconHeight(40);
			setMenuIconWidth(40);
			setMenuTitlePaddingTop(1);
			setMenuTitleMarginLeft(14);
			setTitlePaddingTop(5);
			setTitlePaddingLeft(14);
			setMenuPaddingLeft(1);
			setCurrentWidth(window.innerWidth);
	    }else if((window.innerWidth>='571')&&(window.innerWidth<='768')){
	    	//this.setState({fontSize:"18px",displayType:"tablet"})
	    	var temporaryWidthOfHeaderTitle=(parseInt((45/100)*(window.innerWidth))+"px")
			var temporaryWidthOfHeaderMenu=(parseInt((55/100)*(window.innerWidth))+"px")
	    	setFontSize("21px");
			setDisplayType("tablet");
			setDisplayCompanyName("inline");
			setWidthOfHeaderTitle(temporaryWidthOfHeaderTitle);
			setWidthOfHeaderMenu(temporaryWidthOfHeaderMenu);
			setMenuIconHeight(40);
			setMenuIconWidth(40);
			setMenuTitlePaddingTop(1);
			setMenuTitleMarginLeft(14);
			setTitlePaddingTop(5);
			setTitlePaddingLeft(14);
			setMenuPaddingLeft(1);
			setCurrentWidth(window.innerWidth);
	    }
	    else if((window.innerWidth>='769')&&(window.innerWidth<='1024')){
	    	var temporaryWidthOfHeaderTitle=(parseInt((35/100)*(window.innerWidth))+"px")
			var temporaryWidthOfHeaderMenu=(parseInt((65/100)*(window.innerWidth))+"px")
	    	setFontSize("21px");
			setDisplayType("small-desktop");
			setWidthOfHeaderTitle(temporaryWidthOfHeaderTitle);
			setWidthOfHeaderMenu(temporaryWidthOfHeaderMenu);
			setCurrentWidth(window.innerWidth);
	    }else if((window.innerWidth>='1025')&&(window.innerWidth<='1200')){
	    	var temporaryWidthOfHeaderTitle=(parseInt((35/100)*(window.innerWidth))+"px")
			var temporaryWidthOfHeaderMenu=(parseInt((65/100)*(window.innerWidth))+"px")
	    	setFontSize("24px");
			setDisplayType("medium-desktop");
			setWidthOfHeaderTitle(temporaryWidthOfHeaderTitle);
			setWidthOfHeaderMenu(temporaryWidthOfHeaderMenu);
			setCurrentWidth(window.innerWidth);
	    	// this.setState({fontSize:"24px",displayType:"medium-desktop"})
	    	// this.setState({widthOfHeaderTitle:temporaryWidthOfHeaderTitle,widthofHeaderMenu:temporaryWidthOfHeaderMenu})
	    	// this.setState({currentWidth:window.innerWidth});
	    }else if((window.innerWidth>='1201')){
	    	var temporaryWidthOfHeaderTitle=(parseInt((35/100)*(window.innerWidth))+"px")
			var temporaryWidthOfHeaderMenu=(parseInt((65/100)*(window.innerWidth))+"px")
	    	setFontSize("25px");
			setDisplayType("large-desktop");
			setWidthOfHeaderTitle(temporaryWidthOfHeaderTitle);
			setWidthOfHeaderMenu(temporaryWidthOfHeaderMenu);
			setCurrentWidth(window.innerWidth);
	    }else{}
	};
	return(
		<DecideMobileOrDesktop menuTitlePaddingTop={menuTitlePaddingTop} menuTitleMarginLeft={menuTitleMarginLeft} titlePaddingTop={titlePaddingTop} titlePaddingLeft={titlePaddingLeft} menuPaddingLeft={menuPaddingLeft} menuIconHeight={menuIconHeight} menuIconWidth={menuIconWidth} updateMainPage={updateCurrentPage} displayCompanyName={displayCompanyName} updateMenuOpened={updateMenuOpened} menuOpened={menuOpened} giveCurrentPage={updateCurrentPage} currentPage={currentPage} widthOfHeaderTitle={widthOfHeaderTitle} widthOfHeaderMenu={widthOfHeaderMenu} fontSize={fontSize} displayType={displayType} currentWidth={currentWidth} topOfThePage={topOfThePage}/>
	);
}

// class ApplicationHeader extends React.Component{
// 	constructor(props){
// 		super(props);
// 		this.state={widthOfHeaderTitle:"",widthOfHeaderMenu:"",fontSize:"",displayType:"",currentPage:this.props.currentPage,menuOpened:this.props.fullScreenedMainApp,displayCompanyName:"inline",menuIconHeight:0,menuIconWidth:0,menuTitlePaddingTop:0,menuTitleMarginLeft:0,titlePaddingTop:0,titlePaddingLeft:0,menuPaddingLeft:0,currentWidth:0,topOfThePage:true};
// 	}
// 	componentWillMount(){
// 		this.renderAppCssBreakpoints();
// 		window.addEventListener("resize",this.renderAppCssBreakpoints);
// 		window.addEventListener("scroll",this.detectTopOfPage);
// 	}
// 	componentWillUnmount(){
//     	window.removeEventListener("resize",this.renderAppCssBreakpoints);
// 		window.removeEventListener("scroll",this.detectTopOfPage);
// 	}
// 	shouldComponentUpdate(){
// 		return true
// 	}
// 	updateCurrentPage=(page)=>{
// 		this.setState({currentPage:page},()=>{console.log(this.state.currentPage)});
// 	}
// 	updateMenuOpened=()=>{
// 		this.setState({menuOpened:!this.state.menuOpened},()=>{
// 			this.props.updateFullScreenedMainApp();
// 			console.log(this.state.menuOpened)
// 		});
// 	}
// 	renderAppCssBreakpoints=()=>{
// 		var temporaryWidthOfHeaderTitle=(parseInt((35/100)*(window.innerWidth))+"px")
// 		//var temporaryWidthOfHeaderExtraLeft = (parseInt((5/100)*(window.innerWidth))+"px")
// 		var temporaryWidthOfHeaderMenu=(parseInt((65/100)*(window.innerWidth))+"px")
// 		//var temporaryWidthOfHeaderExtraRight = (parseInt((5/100)*(window.innerWidth))+"px")

// 		//this.setState({widthOfHeaderTitle:temporaryWidthOfHeaderTitle,widthofHeaderMenu:temporaryWidthOfHeaderMenu,widthOfHeaderExtraLeft:temporaryWidthOfHeaderExtraLeft,widthOfHeaderExtraRight:temporaryWidthOfHeaderExtraRight})
	  	

// 		if((window.innerWidth>='320')&&(window.innerWidth<='480')){
// 			var temporaryWidthOfHeaderTitle=(parseInt((70/100)*(window.innerWidth))+"px")
// 			var temporaryWidthOfHeaderMenu=(parseInt((30/100)*(window.innerWidth))+"px")
// 			this.setState({fontSize:"23px",displayType:"mobile",displayCompanyName:"none"})
// 			this.setState({widthOfHeaderTitle:temporaryWidthOfHeaderTitle,widthofHeaderMenu:temporaryWidthOfHeaderMenu})
// 	    	this.setState({menuIconHeight:35,menuIconWidth:35})
// 	    	this.setState({menuTitlePaddingTop:4,menuTitleMarginLeft:14,titlePaddingTop:3,titlePaddingLeft:14,menuPaddingLeft:1});
// 	    	this.setState({currentWidth:window.innerWidth});
// 	    }else if((window.innerWidth>='481')&&(window.innerWidth<='570')){
// 	    	//this.setState({fontSize:"18px",displayType:"tablet"})
// 	    	var temporaryWidthOfHeaderTitle=(parseInt((45/100)*(window.innerWidth))+"px")
// 			var temporaryWidthOfHeaderMenu=(parseInt((55/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"23px",displayType:"tablet",displayCompanyName:"none"})
// 	    	this.setState({widthOfHeaderTitle:temporaryWidthOfHeaderTitle,widthofHeaderMenu:temporaryWidthOfHeaderMenu})
// 	    	this.setState({menuIconHeight:40,menuIconWidth:40})
// 	    	this.setState({menuTitlePaddingTop:1,menuTitleMarginLeft:14,titlePaddingTop:5,titlePaddingLeft:14,menuPaddingLeft:1});
// 	    	this.setState({currentWidth:window.innerWidth});
// 	    }else if((window.innerWidth>='571')&&(window.innerWidth<='768')){
// 	    	//this.setState({fontSize:"18px",displayType:"tablet"})
// 	    	var temporaryWidthOfHeaderTitle=(parseInt((45/100)*(window.innerWidth))+"px")
// 			var temporaryWidthOfHeaderMenu=(parseInt((55/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"23px",displayType:"tablet",displayCompanyName:"inline"})
// 	    	this.setState({widthOfHeaderTitle:temporaryWidthOfHeaderTitle,widthofHeaderMenu:temporaryWidthOfHeaderMenu})
// 	    	this.setState({menuIconHeight:40,menuIconWidth:40})
// 	    	this.setState({menuTitlePaddingTop:1,menuTitleMarginLeft:14,titlePaddingTop:5,titlePaddingLeft:14,menuPaddingLeft:1});
// 	    	this.setState({currentWidth:window.innerWidth});
// 	    }
// 	    else if((window.innerWidth>='769')&&(window.innerWidth<='1024')){
// 	    	var temporaryWidthOfHeaderTitle=(parseInt((35/100)*(window.innerWidth))+"px")
// 			var temporaryWidthOfHeaderMenu=(parseInt((65/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"21px",displayType:"small-desktop"})
// 	    	this.setState({widthOfHeaderTitle:temporaryWidthOfHeaderTitle,widthofHeaderMenu:temporaryWidthOfHeaderMenu})
// 	    	this.setState({currentWidth:window.innerWidth});
// 	      //this.setState({mainDrawerOpen:true,mainDrawerDocked:true,drawerWidth:'225px',mode:'mobile',uimode:"mobileWithSidebar"});
// 	    }else if((window.innerWidth>='1025')&&(window.innerWidth<='1200')){
// 	    	var temporaryWidthOfHeaderTitle=(parseInt((35/100)*(window.innerWidth))+"px")
// 			var temporaryWidthOfHeaderMenu=(parseInt((65/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"24px",displayType:"medium-desktop"})
// 	    	this.setState({widthOfHeaderTitle:temporaryWidthOfHeaderTitle,widthofHeaderMenu:temporaryWidthOfHeaderMenu})
// 	    	this.setState({currentWidth:window.innerWidth});
// 	      //this.setState({mainDrawerOpen:true,mainDrawerDocked:true,drawerWidth:'225px',mode:'desktop',uimode:""});
// 	    }else if((window.innerWidth>='1201')){
// 	    	var temporaryWidthOfHeaderTitle=(parseInt((35/100)*(window.innerWidth))+"px")
// 			var temporaryWidthOfHeaderMenu=(parseInt((65/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"25px",displayType:"large-desktop"})
// 	    	this.setState({widthOfHeaderTitle:temporaryWidthOfHeaderTitle,widthofHeaderMenu:temporaryWidthOfHeaderMenu})
// 	    	this.setState({currentWidth:window.innerWidth});
// 	      //temporaryWidthOfHeaderTitle=(parseInt((25/100)*(window.innerWidth-88))+"px")
// 	      //temporaryWidthOfHeaderMenu=(parseInt((85/100)*(window.innerWidth-88))+"px")
// 	      //this.setState({mainDrawerOpen:true,mainDrawerDocked:true,drawerWidth:'225px',mode:'desktop',uimode:""});
// 	    }else{}
// 	     console.log("width of screen is "+window.innerWidth)
// 	    // console.log(temporaryWidthOfHeaderTitle);
// 	    // console.log(temporaryWidthOfHeaderMenu);
// 	};

// 	detectTopOfPage=()=>{
// 		if((window.scrollX<=0)&&(window.scrollY<=0)){
// 			this.setState({topOfThePage:true});
// 		}else{
// 			this.setState({topOfThePage:false});
// 		}

// 	}
// 	render(){
// 		return(
// 			<DecideMobileOrDesktop menuTitlePaddingTop={this.state.menuTitlePaddingTop} menuTitleMarginLeft={this.state.menuTitleMarginLeft} titlePaddingTop={this.state.titlePaddingTop} titlePaddingLeft={this.state.titlePaddingLeft} menuPaddingLeft={this.state.menuPaddingLeft} menuIconHeight={this.state.menuIconHeight} menuIconWidth={this.state.menuIconWidth} updateMainPage={this.props.updateCurrentPage} displayCompanyName={this.state.displayCompanyName} updateMenuOpened={this.updateMenuOpened} menuOpened={this.state.menuOpened} giveCurrentPage={this.updateCurrentPage} currentPage={this.props.currentPage} widthOfHeaderTitle={this.state.widthOfHeaderTitle} widthOfHeaderMenu={this.state.widthOfHeaderMenu} fontSize={this.state.fontSize} displayType={this.state.displayType} currentWidth={this.state.currentWidth} topOfThePage={this.props.topOfThePage}/>
// 		);
// 	}
// }

function ApplicationHeaderMobile({menuTitlePaddingTop, menuTitleMarginLeft, titlePaddingTop, titlePaddingLeft, menuPaddingLeft, menuIconWidth, menuIconHeight, updateMainPage, updateMenuOpened, menuOpened, giveCurrentPage, currentPage, widthOfHeaderTitle, widthOfHeaderMenu, fontSize, displayType, displayCompanyName}){
	const[widthOfHeaderTitleState,setWidthOfHeaderTitleState]=useState(widthOfHeaderTitle);
	const[widthOfHeaderMenuState,setWidthOfHeaderMenuState]=useState(widthOfHeaderMenu);
	const[fontSizeState,setFontSizeState]=useState(fontSize);
	const[displayCompanyNameState,setDisplayCompanyNameState]=useState(displayCompanyName);
	const[currentPageState,setCurrentPageState]=useState(currentPage);
	const[menuOpenedState,setMenuOpenedState]=useState(menuOpened);

	function passingPageNamePropsToParent(valueToPassAsProps){
		giveCurrentPage(valueToPassAsProps);
	}
	function updateMenuOpenedState(){
		//console.log(!this.state.menuOpened)
		setMenuOpenedState(!menuOpenedState);
		updateMenuOpened(!menuOpenedState);

		// this.setState({menuOpened:!this.state.menuOpened},()=>{
		// 	this.props.updateMenuOpened(this.state.menuOpened);
		// })
	}

	function passingPageNamePropsToParent(valueToPassAsProps){
		giveCurrentPage(valueToPassAsProps);
	}
	useEffect(()=>{
		if (widthOfHeaderTitle !== widthOfHeaderTitleState) {
		    setWidthOfHeaderTitleState(widthOfHeaderTitle);
		  }else if (widthOfHeaderMenu !== widthOfHeaderMenuState) {
		    setWidthOfHeaderMenuState(widthOfHeaderMenu);
		  }else if (fontSize !== fontSizeState) {
		    setFontSizeState(fontSize);
		  }
		  if(currentPage!==currentPageState){
		  	setCurrentPageState(currentPage);
		  }
	},[widthOfHeaderTitle,widthOfHeaderMenu,fontSize,currentPage]);

	return(
		<div className={styles.mainHeader}>
			<div className="w3-row">
				<div className="w3-col" style={{position:"absolute",top:"17%",float:"left",clear:"both",width:"50%",marginLeft:menuTitleMarginLeft,paddingTop:menuTitlePaddingTop}}>
					<img src={ImageMenu} style={{borderStyle:"solid",borderWidth:"1px 1px 1px 1px",float:"left",display:"inline",verticalAlign:"middle"}} height={menuIconHeight} width={menuIconWidth} onClick={updateMenuOpenedState}/>
					<div>
						<span style={{display:"inline",fontSize:`${fontSizeState}`,float:"left",paddingLeft:titlePaddingLeft,paddingTop:titlePaddingTop}}>{currentPageState}</span>
					</div>
				</div>
				<div className="w3-col" style={{width:"50%",float:"right",paddingTop:"12px"}}>					
					<div onClick={()=>{updateMainPage("HOME")}} style={{fontSize:`${fontSizeState}`,paddingRight:10,paddingTop:5,display:`${displayCompanyName}`,verticalAlign:"middle"}}>Repomean Labs</div>
					<img onClick={()=>{updateMainPage("HOME")}} style={{display:"inline",padding:3,verticalAlign:"middle",paddingTop:12,paddingRight:16}} src={ImageLogo} height="35" width="80"/>									
				</div>
			</div>	
		</div>
	);
}

// class ApplicationHeaderMobile extends React.Component{
// 	constructor(props){
// 		super(props);
// 		console.log(props.menuIconWidth);
// 		console.log(props.menuIconHeight);
// 		this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,displayCompanyName:this.props.displayCompanyName,currentPage:this.props.currentPage,menuOpened:this.props.menuOpened}
		
// 	}
// 	shouldComponentUpdate(){
// 		return true
// 	}
// 	componentDidUpdate(prevProps){
// 		  if (this.props.widthOfHeaderTitle !== prevProps.widthOfHeaderTitle) {
// 		    this.setState({widthOfHeaderTitle:this.props.widthOfHeaderTitle})
// 		  }else if (this.props.widthOfHeaderMenu !== prevProps.widthOfHeaderMenu) {
// 		    this.setState({widthOfHeaderMenu:this.props.widthOfHeaderMenu})
// 		  }else if (this.props.fontSize !== prevProps.fontSize) {
// 		    this.setState({fontSize:this.props.fontSize})
// 		  }
// 		  if(this.props.currentPage!==prevProps.currentPage){
// 		  	this.setState({currentPage:this.props.currentPage});
// 		  }
// 	}
// 	passingPageNamePropsToParent=(valueToPassAsProps)=>{
// 		this.props.giveCurrentPage(valueToPassAsProps);
// 	}
// 	updateMenuOpened=()=>{
// 		//console.log(!this.state.menuOpened)
// 		this.setState({menuOpened:!this.state.menuOpened},()=>{
// 			this.props.updateMenuOpened(this.state.menuOpened);
// 		})
// 	}
// 	render(){
// 		return(
// 			<div className={styles.mainHeader}>

// 				<div style={{position:"absolute",top:"17%",float:"left",clear:"both",width:`${this.state.widthOfHeaderTitle}`,marginLeft:this.props.menuTitleMarginLeft,paddingTop:this.props.menuTitlePaddingTop}}>
// 					<img src={ImageMenu} style={{borderStyle:"solid",borderWidth:"1px 1px 1px 1px",float:"left",display:"inline",verticalAlign:"middle"}} height={this.props.menuIconHeight} width={this.props.menuIconWidth} onClick={this.updateMenuOpened}/>
// 					<div>
// 						<span style={{display:"inline",fontSize:`${this.state.fontSize}`,float:"left",paddingLeft:this.props.titlePaddingLeft,paddingTop:this.props.titlePaddingTop}}>{this.state.currentPage}</span>
// 					</div>
// 				</div>
				
// 				<div style={{width:`${this.state.widthOfHeaderMenu}`,float:"right"}}>					
// 					<div onClick={()=>{this.props.updateMainPage("HOME")}} style={{fontSize:`${this.state.fontSize}`,paddingRight:10,paddingTop:5,display:`${this.props.displayCompanyName}`,verticalAlign:"middle"}}>Repomean Labs</div>
// 					<img onClick={()=>{this.props.updateMainPage("HOME")}} style={{display:"inline",padding:3,verticalAlign:"middle",paddingTop:12,paddingRight:16}} src={ImageLogo} height="35" width="80"/>				
										
// 				</div>

// 			</div>	
// 		);
// 	}
// }

function ApplicationHeaderDesktop({updateMainPage,currentPage,widthOfHeaderTitle,widthOfHeaderMenu,fontSize,currentWidth}){
	const[widthOfHeaderTitleState,setWidthOfHeaderTitleState]=useState(widthOfHeaderTitle);
	const[widthOfHeaderMenuState,setWidthOfHeaderMenuState]=useState(widthOfHeaderMenu);
	const[fontSizeState,setFontSizeState]=useState(fontSize);
	const[currentPageState,setCurrentPageState]=useState(currentPage);
	const[currentWidthState,setCurrentWidthState]=useState(currentWidth);
	const[buttonStyle,setButtonStyle]=useState({
		careerTextColor:"#000000",
		careerBackgroundColor:"#ffffff",
		newsTextColor:"#000000",
		newsBackgroundColor:"#ffffff",
		servicesTextColor:"#ffffff",
		servicesBackgroundColor:"#000000"
	});
	useEffect(()=>{
		if(currentPage=="SERVICES"){
			setWidthOfHeaderTitleState(widthOfHeaderTitle);
			setWidthOfHeaderMenuState(widthOfHeaderMenu);
			setFontSizeState(fontSize);
			setCurrentPageState(currentPage);
			setCurrentWidthState(currentWidth);
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#ffffff",
				servicesBackgroundColor:"#000000"
			});
			// this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,
			// 	buttonStyle:{
			// 		careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
			// 	},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
		}else if(currentPage=="NEWS"){
			setWidthOfHeaderTitleState(widthOfHeaderTitle);
			setWidthOfHeaderMenuState(widthOfHeaderMenu);
			setFontSizeState(fontSize);
			setCurrentPageState(currentPage);
			setCurrentWidthState(currentWidth);
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#ffffff",
				newsBackgroundColor:"#000000",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// },currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
		}else if(currentPage=="CAREER"){
			setWidthOfHeaderTitleState(widthOfHeaderTitle);
			setWidthOfHeaderMenuState(widthOfHeaderMenu);
			setFontSizeState(fontSize);
			setCurrentPageState(currentPage);
			setCurrentWidthState(currentWidth);
			setButtonStyle({...buttonStyle,
				careerTextColor:"#ffffff",
				careerBackgroundColor:"#000000",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
			// 	careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// },currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
		}else{
			setWidthOfHeaderTitleState(widthOfHeaderTitle);
			setWidthOfHeaderMenuState(widthOfHeaderMenu);
			setFontSizeState(fontSize);
			setCurrentPageState(currentPage);
			setCurrentWidthState(currentWidth);
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// },currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
		}
	},[fontSize]);
	useEffect(()=>{

		  // if (this.props.widthOfHeaderTitle !== prevProps.widthOfHeaderTitle) {
		  //   this.setState({widthOfHeaderTitle:this.props.widthOfHeaderTitle})
		  // }else if (this.props.widthOfHeaderMenu !== prevProps.widthOfHeaderMenu) {
		  //   this.setState({widthOfHeaderMenu:this.props.widthOfHeaderMenu})
		  // }else if (this.props.fontSize !== prevProps.fontSize) {
		  //   this.setState({fontSize:this.props.fontSize})
		  // }

		if(widthOfHeaderTitleState!==widthOfHeaderTitle){
			setWidthOfHeaderTitleState(widthOfHeaderTitle);
		}else if(widthOfHeaderMenuState!==widthOfHeaderMenu){
			setWidthOfHeaderMenuState(widthOfHeaderMenu);
		}else if(fontSizeState!==fontSize){
			setFontSizeState(fontSize);
		}
	},[widthOfHeaderTitle,widthOfHeaderMenu,fontSize]);

	function onMouseOverServices(){
		if(currentPage=="SERVICES"){}
		else if(currentPage=="NEWS"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#FFFFFF",
				servicesBackgroundColor:"#000000"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#FFFFFF",servicesBackgroundColor:"#000000"
			// }})
		}
		else if(currentPage=="CAREER"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#FFFFFF",
				servicesBackgroundColor:"#000000"
			});
		}else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#ffffff",
				servicesBackgroundColor:"#000000"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
			// }})
		}
	}
	function onMouseOutServices(){
		if(currentPage=="SERVICES"){}
		else if(currentPage=="NEWS"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			})
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}
		else if(currentPage=="CAREER"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			})
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}
	}
	function onMouseOverCareer(){
		if(currentPage=="CAREER"){}
		else if(currentPage=="NEWS"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#ffffff",
				careerBackgroundColor:"#000000",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}
		else if(currentPage=="SERVICES"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#ffffff",
				careerBackgroundColor:"#000000",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			})
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}
		else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#ffffff",
				careerBackgroundColor:"#000000",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			})
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}
	}
	function onMouseOutCareer(){
		if(currentPage=="CAREER"){}
		else if(currentPage=="NEWS"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}
		else if(currentPage=="SERVICES"){
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})	
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
		}
		else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}
	}
	function onMouseOverNews(){
		if(currentPage=="NEWS"){}
		else if(currentPage=="SERVICES"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#ffffff",
				newsBackgroundColor:"#000000",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
		}
		else if(currentPage=="CAREER"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#ffffff",
				newsBackgroundColor:"#000000",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#FFFFFF"
			});
			
		}
		else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#ffffff",
				newsBackgroundColor:"#000000",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
			
		}
	}
	function onMouseOutNews(){
		if(currentPage=="NEWS"){}
		else if(currentPage=="SERVICES"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}
		else if(currentPage=="CAREER"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}	
	}
	function onServicesSelected(){
		updateMainPage("SERVICES"); 
		setButtonStyle({...buttonStyle,
			careerTextColor:"#000000",
			careerBackgroundColor:"#ffffff",
			newsTextColor:"#000000",
			newsBackgroundColor:"#ffffff",
			servicesTextColor:"#ffffff",
			servicesBackgroundColor:"#000000"
		})
		// this.setState({buttonStyle:{
		// 		careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
		// 	}
		// });
		window.scrollTo(0, 1);
	}
	function onNewsSelected(){
		updateMainPage("NEWS"); 
		setButtonStyle({...buttonStyle,
			careerTextColor:"#000000",
			careerBackgroundColor:"#ffffff",
			newsTextColor:"#ffffff",
			newsBackgroundColor:"#000000",
			servicesTextColor:"#000000",
			servicesBackgroundColor:"#ffffff"
		})
		// this.setState({buttonStyle:{
		// 		careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
		// 	}
		// });
		window.scrollTo(0, 1);
	}
	function onCareerSelected(){
		updateMainPage("CAREER");
		setButtonStyle({...buttonStyle,
			careerTextColor:"#ffffff",
			careerBackgroundColor:"#000000",
			newsTextColor:"#000000",
			newsBackgroundColor:"#ffffff",
			servicesTextColor:"#000000",
			servicesBackgroundColor:"#ffffff"
		})
		// this.setState({buttonStyle:{
		// 		careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
		// 	}
		// });
		window.scrollTo(0, 1);
	}
	return(
		<div className="w3-row" style={{width:"100%",top:"0px",position:"fixed",padding:"4px",backgroundColor:"#FFFFFF",boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)"}}>
			<div className="w3-col" style={{position:"absolute",top:"17%",float:"left",clear:"both",width:`${widthOfHeaderTitle}`}} onClick={()=>{updateMainPage("HOME")}}>
				<img style={{display:"inline",padding:3,paddingRight:10,verticalAlign:"middle"}} src={ImageLogo} height="35" width="80"/>
				<p style={{fontSize:`${fontSizeState}`,display:"inline",verticalAlign:"middle",lineHeight:2}}>Repomean Labs</p>
			</div>
			
			<div className="w3-col" style={{margin:8,width:`${widthOfHeaderMenu}`,float:"right"}}>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}>&nbsp;</span>
				</ul>
				<div style={{float:"right",borderWidth:'1px',borderStyle:'solid',color:`${buttonStyle.careerTextColor}`,backgroundColor:`${buttonStyle.careerBackgroundColor}`,borderStyle:"solid",borderWidth:"1px"}} onMouseOver={()=>{onMouseOverCareer()}} onMouseOut={()=>{onMouseOutCareer()}} onClick={()=>{ onCareerSelected();}}>
					<span style={{display:"inline",margin:15,lineHeight: 2,fontSize:`${fontSizeState}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right",borderWidth:'1px',borderStyle:'solid',color:`${buttonStyle.newsTextColor}`,backgroundColor:`${buttonStyle.newsBackgroundColor}`}} onMouseOver={()=>{onMouseOverNews()}} onMouseOut={()=>{onMouseOutNews()}} onClick={()=>{onNewsSelected();}}>
					<span style={{display:"inline",margin:15,lineHeight: 2,fontSize:`${fontSizeState}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right",borderWidth:'1px',borderStyle:'solid',color:`${buttonStyle.servicesTextColor}`,backgroundColor:`${buttonStyle.servicesBackgroundColor}`}} onMouseOver={()=>{onMouseOverServices()}} onMouseOut={()=>{onMouseOutServices()}} onClick={()=>{onServicesSelected();}}>
					<span style={{display:"inline",margin:15,lineHeight: 2,fontSize:`${fontSizeState}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				
			</div>
		</div>
	);

}

// class ApplicationHeaderDesktop extends React.Component{
// 	constructor(props){
// 		super(props);
// 		if(this.props.currentPage=="SERVICES"){
// 			this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,
// 				buttonStyle:{
// 					careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
// 				},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
// 		}else if(this.props.currentPage=="NEWS"){
// 			this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
// 		}else if(this.props.currentPage=="CAREER"){
// 			this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
// 		}else{
// 			this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
// 		}	
// 	}
// 	shouldComponentUpdate(){
// 		return true
// 	}
// 	componentDidUpdate(prevProps){
// 		  if (this.props.widthOfHeaderTitle !== prevProps.widthOfHeaderTitle) {
// 		    this.setState({widthOfHeaderTitle:this.props.widthOfHeaderTitle})
// 		  }else if (this.props.widthOfHeaderMenu !== prevProps.widthOfHeaderMenu) {
// 		    this.setState({widthOfHeaderMenu:this.props.widthOfHeaderMenu})
// 		  }else if (this.props.fontSize !== prevProps.fontSize) {
// 		    this.setState({fontSize:this.props.fontSize})
// 		  }
// 	}
// 	onMouseOverServices=()=>{
// 		if(this.props.currentPage=="SERVICES"){}
// 		else if(this.props.currentPage=="NEWS"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#FFFFFF",servicesBackgroundColor:"#000000"
// 			}})
// 		}
// 		else if(this.props.currentPage=="CAREER"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#FFFFFF",servicesBackgroundColor:"#000000"
// 			}})
// 		}else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
// 			}})
// 		}
// 	}
// 	onMouseOutServices=()=>{
// 		if(this.props.currentPage=="SERVICES"){}
// 		else if(this.props.currentPage=="NEWS"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}
// 		else if(this.props.currentPage=="CAREER"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}
// 	}
// 	onMouseOverCareer=()=>{
// 		if(this.props.currentPage=="CAREER"){}
// 		else if(this.props.currentPage=="NEWS"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}
// 		else if(this.props.currentPage=="SERVICES"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 		else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 	}
// 	onMouseOutCareer=()=>{
// 		if(this.props.currentPage=="CAREER"){}
// 		else if(this.props.currentPage=="NEWS"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}
// 		else if(this.props.currentPage=="SERVICES"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})	
// 		}
// 		else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 	}
// 	onMouseOverNews=()=>{
// 		if(this.props.currentPage=="NEWS"){}
// 		else if(this.props.currentPage=="SERVICES"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 		else if(this.props.currentPage=="CAREER"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#FFFFFF"
// 			}})
// 		}
// 		else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 	}
// 	onMouseOutNews=()=>{
// 		if(this.props.currentPage=="NEWS"){}
// 		else if(this.props.currentPage=="SERVICES"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 		else if(this.props.currentPage=="CAREER"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}	
// 	}
// 	onServicesSelected=()=>{
// 		this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
// 			}
// 		});
// 		window.scrollTo(0, 1);
// 	}
// 	onNewsSelected=()=>{
// 		this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}
// 		});
// 		window.scrollTo(0, 1);
// 	}
// 	onCareerSelected=()=>{
// 		this.setState({buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}
// 		});
// 		window.scrollTo(0, 1);
// 	}
// 	render(){
// 			return(
// 				<div className={styles.mainHeader}>
// 					<div style={{position:"absolute",top:"17%",float:"left",clear:"both",width:`${this.state.widthOfHeaderTitle}`}} onClick={()=>{this.props.updateMainPage("HOME")}}>
// 						<img style={{display:"inline",padding:3,paddingRight:10,verticalAlign:"middle"}} src={ImageLogo} height="35" width="80"/>
// 						<p style={{fontSize:`${this.state.fontSize}`,display:"inline",verticalAlign:"middle",lineHeight:2}}>Repomean Labs</p>
// 					</div>
					
// 					<div style={{margin:8,width:`${this.state.widthOfHeaderMenu}`}}>
// 						<ul style={{float:"right"}}>
// 							<span style={{display:"inline"}}>&nbsp;</span>
// 						</ul>
// 						<div style={{float:"right",borderWidth:'1px',borderStyle:'solid',color:`${this.state.buttonStyle.careerTextColor}`,backgroundColor:`${this.state.buttonStyle.careerBackgroundColor}`}} onMouseOver={()=>{this.onMouseOverCareer()}} onMouseOut={()=>{this.onMouseOutCareer()}} onClick={()=>{this.props.updateMainPage("CAREER"); this.onCareerSelected();}}>
// 							<span style={{display:"inline",margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}>Career</span>
// 						</div>
// 						<ul style={{float:"right"}}>
// 							<span style={{display:"inline"}}></span>
// 						</ul>
// 						<div style={{float:"right",borderWidth:'1px',borderStyle:'solid',color:`${this.state.buttonStyle.newsTextColor}`,backgroundColor:`${this.state.buttonStyle.newsBackgroundColor}`}} onMouseOver={()=>{this.onMouseOverNews()}} onMouseOut={()=>{this.onMouseOutNews()}} onClick={()=>{this.props.updateMainPage("NEWS"); this.onNewsSelected();}}>
// 							<span style={{display:"inline",margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}>News</span>
// 						</div>
// 						<ul style={{float:"right"}}>
// 							<span style={{display:"inline"}}></span>
// 						</ul>
// 						<div style={{float:"right",borderWidth:'1px',borderStyle:'solid',color:`${this.state.buttonStyle.servicesTextColor}`,backgroundColor:`${this.state.buttonStyle.servicesBackgroundColor}`}} onMouseOver={()=>{this.onMouseOverServices()}} onMouseOut={()=>{this.onMouseOutServices()}} onClick={()=>{this.props.updateMainPage("SERVICES"); this.onServicesSelected();}}>
// 							<span style={{display:"inline",margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}>Services</span>
// 						</div>
// 						<ul style={{float:"right"}}>
// 							<span style={{display:"inline"}}></span>
// 						</ul>
						
// 					</div>
// 				</div>
// 			);
// 	}
// }

function ReturnTopOfThePageHeader({updateMainPage,currentPage,widthOfHeaderTitle,widthOfHeaderMenu,fontSize,currentWidth}){
	const[widthOfHeaderTitleState,setWidthOfHeaderTitleState]=useState(widthOfHeaderTitle);
	const[widthOfHeaderMenuState,setWidthOfHeaderMenuState]=useState(widthOfHeaderMenu);
	const[fontSizeState,setFontSizeState]=useState(fontSize);
	const[currentPageState,setCurrentPageState]=useState(currentPage);
	const[currentWidthState,setCurrentWidthState]=useState(currentWidth);
	const[buttonStyle,setButtonStyle]=useState({
		careerTextColor:"#000000",
		careerBackgroundColor:"#ffffff",
		newsTextColor:"#000000",
		newsBackgroundColor:"#ffffff",
		servicesTextColor:"#000000",
		servicesBackgroundColor:"#ffffff"
	});
	// useEffect(()=>{
	// 	alert(fontSize);
	// 	if(currentPage=="SERVICES"){
	// 		setWidthOfHeaderTitleState(widthOfHeaderTitle);
	// 		setWidthOfHeaderMenuState(widthOfHeaderMenu);
	// 		setFontSizeState(fontSize);
	// 		setCurrentPageState(currentPage);
	// 		setCurrentWidthState(currentWidth);
	// 		setButtonStyle({...buttonStyle,
	// 			careerTextColor:"#000000",
	// 			careerBackgroundColor:"#ffffff",
	// 			newsTextColor:"#000000",
	// 			newsBackgroundColor:"#ffffff",
	// 			servicesTextColor:"#ffffff",
	// 			servicesBackgroundColor:"#000000"
	// 		});
	// 		// this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,
	// 		// 	buttonStyle:{
	// 		// 		careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
	// 		// 	},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
	// 	}else if(currentPage=="NEWS"){
	// 		setWidthOfHeaderTitleState(widthOfHeaderTitle);
	// 		setWidthOfHeaderMenuState(widthOfHeaderMenu);
	// 		setFontSizeState(fontSize);
	// 		setCurrentPageState(currentPage);
	// 		setCurrentWidthState(currentWidth);
	// 		setButtonStyle({...buttonStyle,
	// 			careerTextColor:"#000000",
	// 			careerBackgroundColor:"#ffffff",
	// 			newsTextColor:"#ffffff",
	// 			newsBackgroundColor:"#000000",
	// 			servicesTextColor:"#000000",
	// 			servicesBackgroundColor:"#ffffff"
	// 		});
	// 		// this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
	// 		// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
	// 		// },currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
	// 	}else if(currentPage=="CAREER"){
	// 		setWidthOfHeaderTitleState(widthOfHeaderTitle);
	// 		setWidthOfHeaderMenuState(widthOfHeaderMenu);
	// 		setFontSizeState(fontSize);
	// 		setCurrentPageState(currentPage);
	// 		setCurrentWidthState(currentWidth);
	// 		setButtonStyle({...buttonStyle,
	// 			careerTextColor:"#ffffff",
	// 			careerBackgroundColor:"#000000",
	// 			newsTextColor:"#000000",
	// 			newsBackgroundColor:"#ffffff",
	// 			servicesTextColor:"#000000",
	// 			servicesBackgroundColor:"#ffffff"
	// 		});
	// 		// this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
	// 		// 	careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
	// 		// },currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
	// 	}else{
	// 		setWidthOfHeaderTitleState(widthOfHeaderTitle);
	// 		setWidthOfHeaderMenuState(widthOfHeaderMenu);
	// 		setFontSizeState(fontSize);
	// 		setCurrentPageState(currentPage);
	// 		setCurrentWidthState(currentWidth);
	// 		setButtonStyle({...buttonStyle,
	// 			careerTextColor:"#000000",
	// 			careerBackgroundColor:"#ffffff",
	// 			newsTextColor:"#000000",
	// 			newsBackgroundColor:"#ffffff",
	// 			servicesTextColor:"#000000",
	// 			servicesBackgroundColor:"#ffffff"
	// 		});
	// 		// this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
	// 		// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
	// 		// },currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
	// 	}
	// },[]);
	useEffect(()=>{
		//alert(fontSize);
		if(widthOfHeaderTitleState!==widthOfHeaderTitle){
			setWidthOfHeaderTitleState(widthOfHeaderTitle);
		}else if(widthOfHeaderMenuState!==widthOfHeaderMenu){
			setWidthOfHeaderMenuState(widthOfHeaderMenu);
		}else if(fontSizeState!==fontSize){
			setFontSizeState(fontSize);
		}
	},[widthOfHeaderTitle,widthOfHeaderMenu,fontSize]);
	function onMouseOverServices(){
		if(currentPage=="SERVICES"){}
		else if(currentPage=="NEWS"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#FFFFFF",
				servicesBackgroundColor:"#000000"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#FFFFFF",servicesBackgroundColor:"#000000"
			// }})
		}
		else if(currentPage=="CAREER"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#FFFFFF",
				servicesBackgroundColor:"#000000"
			});
		}else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#ffffff",
				servicesBackgroundColor:"#000000"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
			// }})
		}
	}
	function onMouseOutServices(){
		if(currentPage=="SERVICES"){}
		else if(currentPage=="NEWS"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			})
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}
		else if(currentPage=="CAREER"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			})
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}
	}
	function onMouseOverCareer(){

		if(currentPage=="CAREER"){}
		else if(currentPage=="NEWS"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#ffffff",
				careerBackgroundColor:"#000000",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}
		else if(currentPage=="SERVICES"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#ffffff",
				careerBackgroundColor:"#000000",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			})
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}
		else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#ffffff",
				careerBackgroundColor:"#000000",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			})
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}
	}
	function onMouseOutCareer(){
		if(currentPage=="CAREER"){}
		else if(currentPage=="NEWS"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}
		else if(currentPage=="SERVICES"){
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})	
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
		}
		else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:buttonStyle.newsTextColor,
				newsBackgroundColor:buttonStyle.newsBackgroundColor,
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}
	}
	function onMouseOverNews(){
		if(currentPage=="NEWS"){}
		else if(currentPage=="SERVICES"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#ffffff",
				newsBackgroundColor:"#000000",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
		}
		else if(currentPage=="CAREER"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#ffffff",
				newsBackgroundColor:"#000000",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#FFFFFF"
			});
			
		}
		else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#ffffff",
				newsBackgroundColor:"#000000",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
			
		}
	}
	function onMouseOutNews(){
		if(currentPage=="NEWS"){}
		else if(currentPage=="SERVICES"){
			setButtonStyle({...buttonStyle,
				careerTextColor:"#000000",
				careerBackgroundColor:"#ffffff",
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}
		else if(currentPage=="CAREER"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:"#000000",
				servicesBackgroundColor:"#ffffff"
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
			// }})
		}else if(currentPage=="HOME"){
			setButtonStyle({...buttonStyle,
				careerTextColor:buttonStyle.careerTextColor,
				careerBackgroundColor:buttonStyle.careerBackgroundColor,
				newsTextColor:"#000000",
				newsBackgroundColor:"#ffffff",
				servicesTextColor:buttonStyle.servicesTextColor,
				servicesBackgroundColor:buttonStyle.servicesBackgroundColor
			});
			// this.setState({buttonStyle:{
			// 	careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
			// }})
		}	
	}
	function onServicesSelected(){
		setButtonStyle({...buttonStyle,
			careerTextColor:"#000000",
			careerBackgroundColor:"#ffffff",
			newsTextColor:"#000000",
			newsBackgroundColor:"#ffffff",
			servicesTextColor:"#ffffff",
			servicesBackgroundColor:"#000000"
		})
		// this.setState({buttonStyle:{
		// 		careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
		// 	}
		// });
		window.scrollTo(0, 1);
	}
	function onNewsSelected(){
		setButtonStyle({...buttonStyle,
			careerTextColor:"#000000",
			careerBackgroundColor:"#ffffff",
			newsTextColor:"#ffffff",
			newsBackgroundColor:"#000000",
			servicesTextColor:"#000000",
			servicesBackgroundColor:"#ffffff"
		})
		// this.setState({buttonStyle:{
		// 		careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
		// 	}
		// });
		window.scrollTo(0, 1);
	}
	function onCareerSelected(){
		setButtonStyle({...buttonStyle,
			careerTextColor:"#ffffff",
			careerBackgroundColor:"#000000",
			newsTextColor:"#000000",
			newsBackgroundColor:"#ffffff",
			servicesTextColor:"#000000",
			servicesBackgroundColor:"#ffffff"
		});
		// this.setState({buttonStyle:{
		// 		careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
		// 	}
		// });
		window.scrollTo(0, 1);
	}
	return(
		   <div style={{borderStyle:'solid',borderWidth:'1px'}}>
			<div style={{height:'40px'}}>
			</div>
			<div className={styles.rowResponsive}>
				<div className={styles.columnResponsive6percent}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}> </span>
				</div>
				<div className={styles.columnResponsive25percent} style={{paddingLeft:"10px"}} onClick={()=>{updateMainPage("HOME")}}>
					<br/><br/><br/><br/><br/><br/><img style={{padding:3,paddingRight:10,verticalAlign:"middle"}} src={ImageLogo} height="35" width="80"/>
					<p style={{fontSize:`${fontSizeState}`,verticalAlign:"middle",lineHeight:2}}>Repomean Labs</p>
				</div>
				<div className={styles.columnResponsive6percent}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}> </span>
				</div>
				<div className={styles.columnResponsive56percent}>
					<p style={{fontSize:"23px",textAlign:"left"}}>WELCOME TO REPOMEAN LABS.</p>
					<p style={{textAlign:"justify"}}>
						<br/><br/> We a software research 
						and development company. Located at 
						Kathmandu, Nepal we cater to all your 
						digital needs ranging from standard
						websites to enterprise softwares. <br/><br/>Our primary services are : Firstly, Research and Development 
						of any digital products (i.e website, enterprise software etc) . Second, Resource and Price Quotation 
						where we quote the resource and cost required for
						installation of or shifting of technology stacks. And finally Advisory and Consultation to lend
						our thoughts and experience for the welfare of our clients.  
						 
					</p>
				</div>
				<div className={styles.columnResponsive6percent}>
					<span style={{lineHeight:1,fontSize:`${fontSize}`}}> </span>
				</div>
		    </div>
		    <div style={{height:'10px'}}>
			</div>
			<div className={styles.rowResponsive} style={{padding:'20px'}}>
				<div className={styles.columnResponsive6percent}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}> </span>
				</div>
				<div className={styles.columnResponsive25percent} style={{borderWidth:'1px',borderStyle:'solid',color:`${buttonStyle.servicesTextColor}`,backgroundColor:`${buttonStyle.servicesBackgroundColor}`}} onMouseOver={()=>{onMouseOverServices()}} onMouseOut={()=>{onMouseOutServices()}} onClick={()=>{updateMainPage("SERVICES"); onServicesSelected();}}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}>Services</span>
				</div>
				<div className={styles.columnResponsive6percent}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}> </span>
				</div>
				<div className={styles.columnResponsive25percent} style={{borderWidth:'1px',borderStyle:'solid',color:`${buttonStyle.newsTextColor}`,backgroundColor:`${buttonStyle.newsBackgroundColor}`}} onMouseOver={()=>{onMouseOverNews()}} onMouseOut={()=>{onMouseOutNews()}} onClick={()=>{updateMainPage("NEWS"); onNewsSelected();}}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}>News</span>
				</div>
				<div className={styles.columnResponsive6percent}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}> </span>
				</div>
				<div className={styles.columnResponsive25percent} style={{borderWidth:'1px',borderStyle:'solid',color:`${buttonStyle.careerTextColor}`,backgroundColor:`${buttonStyle.careerBackgroundColor}`}} onMouseOver={()=>{onMouseOverCareer()}} onMouseOut={()=>{onMouseOutCareer()}} onClick={()=>{updateMainPage("CAREER"); onCareerSelected();}}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}>Career</span>
				</div>
				<div className={styles.columnResponsive6percent}>
					<span style={{margin:15,lineHeight: 2,fontSize:`${fontSize}`}}> </span>
				</div>
			</div>
		   </div>
		)
}

// class ReturnTopOfThePageHeader extends React.Component {
// 	constructor(props){
// 		super(props);
// 		if(this.props.currentPage=="SERVICES"){
// 			this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
// 			},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
// 		}else if(this.props.currentPage=="NEWS"){
// 			this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
// 		}else if(this.props.currentPage=="CAREER"){
// 			this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
// 		}else{
// 			this.state={widthOfHeaderTitle:this.props.widthOfHeaderTitle,widthofHeaderMenu:this.props.widthofHeaderMenu,fontSize:this.props.fontSize,buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			},currentPage:this.props.currentPage,currentWidth:this.props.currentWidth}
// 		}
// 	}
// 	shouldComponentUpdate(){
// 		return true
// 	}
// 	onMouseOverServices=()=>{
// 		if(this.props.currentPage=="SERVICES"){}
// 		else if(this.props.currentPage=="NEWS"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#FFFFFF",servicesBackgroundColor:"#000000"
// 			}})
// 		}
// 		else if(this.props.currentPage=="CAREER"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#FFFFFF",servicesBackgroundColor:"#000000"
// 			}})
// 		}else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
// 			}})
// 		}
// 	}
// 	onMouseOutServices=()=>{
// 		if(this.props.currentPage=="SERVICES"){}
// 		else if(this.props.currentPage=="NEWS"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}
// 		else if(this.props.currentPage=="CAREER"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}
// 	}
// 	onMouseOverCareer=()=>{
// 		if(this.props.currentPage=="CAREER"){}
// 		else if(this.props.currentPage=="NEWS"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}
// 		else if(this.props.currentPage=="SERVICES"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 		else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 	}
// 	onMouseOutCareer=()=>{
// 		if(this.props.currentPage=="CAREER"){}
// 		else if(this.props.currentPage=="NEWS"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}
// 		else if(this.props.currentPage=="SERVICES"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})	
// 		}
// 		else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:this.state.buttonStyle.newsTextColor,newsBackgroundColor:this.state.buttonStyle.newsBackgroundColor,servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 	}
// 	onMouseOverNews=()=>{
// 		if(this.props.currentPage=="NEWS"){}
// 		else if(this.props.currentPage=="SERVICES"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 		else if(this.props.currentPage=="CAREER"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#FFFFFF"
// 			}})
// 		}
// 		else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 	}
// 	onMouseOutNews=()=>{
// 		if(this.props.currentPage=="NEWS"){}
// 		else if(this.props.currentPage=="SERVICES"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}
// 		else if(this.props.currentPage=="CAREER"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}})
// 		}else if(this.props.currentPage=="HOME"){
// 			this.setState({buttonStyle:{
// 				careerTextColor:this.state.buttonStyle.careerTextColor,careerBackgroundColor:this.state.buttonStyle.careerBackgroundColor,newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:this.state.buttonStyle.servicesTextColor,servicesBackgroundColor:this.state.buttonStyle.servicesBackgroundColor
// 			}})
// 		}	
// 	}
// 	onServicesSelected=()=>{
// 		this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#ffffff",servicesBackgroundColor:"#000000"
// 			}
// 		});
// 		window.scrollTo(0, 0);
// 	}
// 	onNewsSelected=()=>{
// 		this.setState({buttonStyle:{
// 				careerTextColor:"#000000",careerBackgroundColor:"#ffffff",newsTextColor:"#ffffff",newsBackgroundColor:"#000000",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}
// 		});
// 		window.scrollTo(0, 0);
// 	}
// 	onCareerSelected=()=>{
// 		this.setState({buttonStyle:{
// 				careerTextColor:"#ffffff",careerBackgroundColor:"#000000",newsTextColor:"#000000",newsBackgroundColor:"#ffffff",servicesTextColor:"#000000",servicesBackgroundColor:"#ffffff"
// 			}
// 		});
// 		window.scrollTo(0, 0);
// 	}
// 	render(){
// 		return(
// 		   <div style={{borderStyle:'solid',borderWidth:'1px'}}>
// 			<div style={{height:'40px'}}>
// 			</div>
// 			<div className={styles.rowResponsive}>
// 				<div className={styles.columnResponsive6percent}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}> </span>
// 				</div>
// 				<div className={styles.columnResponsive25percent} style={{paddingLeft:"10px"}} onClick={()=>{this.props.updateMainPage("HOME")}}>
// 					<br/><br/><br/><br/><br/><br/><img style={{padding:3,paddingRight:10,verticalAlign:"middle"}} src={ImageLogo} height="35" width="80"/>
// 					<p style={{fontSize:`${this.state.fontSize}`,verticalAlign:"middle",lineHeight:2}}>Repomean Labs</p>
// 				</div>
// 				<div className={styles.columnResponsive6percent}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}> </span>
// 				</div>
// 				<div className={styles.columnResponsive56percent}>
					
// 					<p style={{fontSize:"23px",textAlign:"left"}}>WELCOME TO REPOMEAN LABS.</p>
// 					<p style={{textAlign:"justify"}}>
// 						<br/><br/> We a software research 
// 						and development company. Located at 
// 						Kathmandu, Nepal we cater to all your 
// 						digital needs ranging from standard
// 						websites to enterprise softwares. <br/><br/>Our primary services are : Firstly, Research and Development 
// 						of any digital products (i.e website, enterprise software etc) . Second, Resource and Price Quotation 
// 						where we quote the resource and cost required for
// 						installation of or shifting of technology stacks. And finally Advisory and Consultation to lend
// 						our thoughts and experience for the welfare of our clients.  
						 

					
// 					</p>
// 				</div>
// 				<div className={styles.columnResponsive6percent}>
// 					<span style={{lineHeight:1,fontSize:`${this.state.fontSize}`}}> </span>
// 				</div>
// 		    </div>
// 		    <div style={{height:'10px'}}>
// 			</div>
// 			<div className={styles.rowResponsive} style={{padding:'20px'}}>
// 				<div className={styles.columnResponsive6percent}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}> </span>
// 				</div>
// 				<div className={styles.columnResponsive25percent} style={{borderWidth:'1px',borderStyle:'solid',color:`${this.state.buttonStyle.servicesTextColor}`,backgroundColor:`${this.state.buttonStyle.servicesBackgroundColor}`}} onMouseOver={()=>{this.onMouseOverServices()}} onMouseOut={()=>{this.onMouseOutServices()}} onClick={()=>{this.props.updateMainPage("SERVICES"); this.onServicesSelected();}}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}>Services</span>
// 				</div>
// 				<div className={styles.columnResponsive6percent}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}> </span>
// 				</div>
// 				<div className={styles.columnResponsive25percent} style={{borderWidth:'1px',borderStyle:'solid',color:`${this.state.buttonStyle.newsTextColor}`,backgroundColor:`${this.state.buttonStyle.newsBackgroundColor}`}} onMouseOver={()=>{this.onMouseOverNews()}} onMouseOut={()=>{this.onMouseOutNews()}} onClick={()=>{this.props.updateMainPage("NEWS"); this.onNewsSelected();}}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}>News</span>
// 				</div>
// 				<div className={styles.columnResponsive6percent}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}> </span>
// 				</div>
// 				<div className={styles.columnResponsive25percent} style={{borderWidth:'1px',borderStyle:'solid',color:`${this.state.buttonStyle.careerTextColor}`,backgroundColor:`${this.state.buttonStyle.careerBackgroundColor}`}} onMouseOver={()=>{this.onMouseOverCareer()}} onMouseOut={()=>{this.onMouseOutCareer()}} onClick={()=>{this.props.updateMainPage("CAREER"); this.onCareerSelected();}}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}>Career</span>
// 				</div>
// 				<div className={styles.columnResponsive6percent}>
// 					<span style={{margin:15,lineHeight: 2,fontSize:`${this.state.fontSize}`}}> </span>
// 				</div>
// 			</div>
// 		   </div>
// 		)
// 	}

// }

function DecideBetweenDesktopHeaderTopOfPageOrNot(props){
	if(props.topOfThePage){
		return <ReturnTopOfThePageHeader updateMainPage={props.updateMainPage} currentPage={props.currentPage} widthOfHeaderTitle={props.widthOfHeaderTitle} widthOfHeaderMenu={props.widthOfHeaderMenu} fontSize={props.fontSize} currentWidth={props.currentWidth}/>;
	}else{
		return <ApplicationHeaderDesktop updateMainPage={props.updateMainPage} currentPage={props.currentPage} widthOfHeaderTitle={props.widthOfHeaderTitle} widthOfHeaderMenu={props.widthOfHeaderMenu} fontSize={props.fontSize} currentWidth={props.currentWidth}/>
	}

}

function DecideMobileOrDesktop(props){
	if((props.displayType=="mobile")||(props.displayType=="tablet")){
		return <ApplicationHeaderMobile menuTitlePaddingTop={props.menuTitlePaddingTop} menuTitleMarginLeft={props.menuTitleMarginLeft} titlePaddingTop={props.titlePaddingTop} titlePaddingLeft={props.titlePaddingLeft} menuPaddingLeft={props.menuPaddingLeft}  menuIconWidth={props.menuIconWidth} menuIconHeight={props.menuIconHeight} updateMainPage={props.updateMainPage} updateMenuOpened={props.updateMenuOpened} menuOpened={props.menuOpened} giveCurrentPage={props.giveCurrentPage} currentPage={props.currentPage} widthOfHeaderTitle={props.widthOfHeaderTitle} widthOfHeaderMenu={props.widthOfHeaderMenu} fontSize={props.fontSize} displayType={props.displayType} displayCompanyName={props.displayCompanyName}/>
	}else{
		return <DecideBetweenDesktopHeaderTopOfPageOrNot updateMainPage={props.updateMainPage} currentPage={props.currentPage} widthOfHeaderTitle={props.widthOfHeaderTitle} widthOfHeaderMenu={props.widthOfHeaderMenu} fontSize={props.fontSize} currentWidth={props.currentWidth} topOfThePage={props.topOfThePage}/>
	}
}

export default ApplicationHeader

