// function ApplicationFooter(){
// 	return(
// 		<div>
// 			<h1> this is footer </h1>
// 		</div>
// 	)
// }
import React, { useState, useEffect } from 'react';
import styles from './ApplicationHeader.module.css';
import ImageLogo from './repomeanLogo.jpg';
import ImageMenu from './menu.png';

function ApplicationFooter(){
	//fontSize:"20px",widthOfTitle:20,widthOfMenu:20,showNavigationButtons:"none",listItemStartCounter:0,listItemStopCounter:
	const[fontSize,setFontSize]=useState("20px");
	const[smallFontSize,setSmallFontSize]=useState("");
	const[displayType,setDisplayType]=useState("none");
	const[footerPaddingBottom,setFooterPaddingBottom]=useState("");
	const[widthOfTitle,setWidthOfTitle]=useState(20);
	const[widthOfMenu,setWidthOfMenu]=useState(20);
	const[menuSize,setMenuSize]=useState("");
	const[showNavigationButtons,setShowNavigationButtons]=useState("none");
	const[listItemStartCounter,setListItemStartCounter]=useState(0);
	const[listItemStopCounter,setListItemStopCounter]=useState(0);

	function renderAppCssBreakpoints(){
		var temporaryWidthOfTitle=0;
		var temporaryWidthOfMenu=0;
		if((window.innerWidth>='320')&&(window.innerWidth<='480')){
			temporaryWidthOfTitle=(parseInt((39/100)*(window.innerWidth))+"px")
			temporaryWidthOfMenu=(parseInt((61/100)*(window.innerWidth))+"px")
			//footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"half-quad-full",listItemStartCounter:1,listItemStopCounter:2
			setFontSize("23px");
			setSmallFontSize("12px");
			setDisplayType("mobile");
			setFooterPaddingBottom("30px");
			setWidthOfTitle(temporaryWidthOfTitle);
			setWidthOfMenu(temporaryWidthOfMenu);
			setMenuSize("half-quad-full");
			setListItemStartCounter(1);
			setListItemStopCounter(2);
			setShowNavigationButtons("block");
			// this.setState({fontSize:"23px",smallFontSize:"12px",displayType:"mobile",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"half-quad-full",listItemStartCounter:1,listItemStopCounter:2});
			// this.setState({showNavigationButtons:"block"});
		}else if((window.innerWidth>='481')&&(window.innerWidth<='570')){
	    	temporaryWidthOfTitle=(parseInt((33/100)*(window.innerWidth))+"px")
			temporaryWidthOfMenu=(parseInt((67/100)*(window.innerWidth))+"px")
	    	setFontSize("23px");
			setSmallFontSize("15px");
			setDisplayType("tablet");
			setFooterPaddingBottom("30px");
			setWidthOfTitle(temporaryWidthOfTitle);
			setWidthOfMenu(temporaryWidthOfMenu);
			setMenuSize("quad-full");
			setListItemStartCounter(1);
			setListItemStopCounter(3);
			setShowNavigationButtons("block");

	    	// this.setState({fontSize:"23px",smallFontSize:"15px",displayType:"tablet",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"quad-full",listItemStartCounter:1,listItemStopCounter:3})
	    	// this.setState({showNavigationButtons:"block"});
	    }else if((window.innerWidth>='571')&&(window.innerWidth<='768')){
	    	temporaryWidthOfTitle=(parseInt((30/100)*(window.innerWidth))+"px")
			temporaryWidthOfMenu=(parseInt((70/100)*(window.innerWidth))+"px")
	    	
	    	setFontSize("23px");
			setSmallFontSize("17px");
			setDisplayType("tablet");
			setFooterPaddingBottom("30px");
			setWidthOfTitle(temporaryWidthOfTitle);
			setWidthOfMenu(temporaryWidthOfMenu);
			setMenuSize("half-not-full");
			setListItemStartCounter(1);
			setListItemStopCounter(4);
			setShowNavigationButtons("block");


	    	// this.setState({fontSize:"23px",smallFontSize:"17px",displayType:"tablet",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"half-not-full",listItemStartCounter:1,listItemStopCounter:4})
	    	// this.setState({showNavigationButtons:"block"});
	    }else if((window.innerWidth>='769')&&(window.innerWidth<='1024')){
	    	temporaryWidthOfTitle=(parseInt((29/100)*(window.innerWidth))+"px")
			temporaryWidthOfMenu=(parseInt((71/100)*(window.innerWidth))+"px")
	    	
			setFontSize("21px");
			setSmallFontSize("20px");
			setDisplayType("small-desktop");
			setFooterPaddingBottom("30px");
			setWidthOfTitle(temporaryWidthOfTitle);
			setWidthOfMenu(temporaryWidthOfMenu);
			setMenuSize("quad-not-full");
			setListItemStartCounter(1);
			setListItemStopCounter(5);
			setShowNavigationButtons("block");


	    	// this.setState({fontSize:"21px",smallFontSize:"20px",displayType:"small-desktop",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"quad-not-full",listItemStartCounter:1,listItemStopCounter:5})
	    	// this.setState({showNavigationButtons:"block"});
	    }else if((window.innerWidth>='1025')&&(window.innerWidth<='1200')){
	    	temporaryWidthOfTitle=(parseInt((28/100)*(window.innerWidth))+"px")
			temporaryWidthOfMenu=(parseInt((72/100)*(window.innerWidth))+"px")
	    	
			setFontSize("24px");
			setSmallFontSize("22px");
			setDisplayType("medium-desktop");
			setFooterPaddingBottom("37px");
			setWidthOfTitle(temporaryWidthOfTitle);
			setWidthOfMenu(temporaryWidthOfMenu);
			setMenuSize("full");
			setListItemStartCounter(1);
			setListItemStopCounter(7);
			setShowNavigationButtons("none");

	    	// this.setState({fontSize:"24px",smallFontSize:"22px",displayType:"medium-desktop",footerPaddingBottom:"37px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
	    	// this.setState({showNavigationButtons:"none"});
	    }else if((window.innerWidth>='1201')&&((window.innerWidth<='1680'))){
	    	temporaryWidthOfTitle=(parseInt((25/100)*(window.innerWidth))+"px")
			temporaryWidthOfMenu=(parseInt((75/100)*(window.innerWidth))+"px")
	    	
			setFontSize("25px");
			setSmallFontSize("24px");
			setDisplayType("large-desktop");
			setFooterPaddingBottom("38px");
			setWidthOfTitle(temporaryWidthOfTitle);
			setWidthOfMenu(temporaryWidthOfMenu);
			setMenuSize("full");
			setListItemStartCounter(1);
			setListItemStopCounter(7);
			setShowNavigationButtons("none");

	    	// this.setState({fontSize:"25px",smallFontSize:"24px",displayType:"large-desktop",footerPaddingBottom:"38px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
	    	// this.setState({showNavigationButtons:"none"});
	    }else if((window.innerWidth>='1681')&&(window.innerWidth<='2201')){
	    	temporaryWidthOfTitle=(parseInt((25/100)*(window.innerWidth))+"px")
			temporaryWidthOfMenu=(parseInt((75/100)*(window.innerWidth))+"px")
	    	

			setFontSize("25px");
			setSmallFontSize("24px");
			setDisplayType("large-desktop");
			setFooterPaddingBottom("39px");
			setWidthOfTitle(temporaryWidthOfTitle);
			setWidthOfMenu(temporaryWidthOfMenu);
			setMenuSize("full");
			setListItemStartCounter(1);
			setListItemStopCounter(7);
			setShowNavigationButtons("none");

	    	// this.setState({fontSize:"25px",smallFontSize:"24px",displayType:"large-desktop",footerPaddingBottom:"39px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
	    	// this.setState({showNavigationButtons:"none"});
	    }else{}
	};

	useEffect(()=>{
		renderAppCssBreakpoints();
		window.addEventListener("resize",renderAppCssBreakpoints);
		return () => {
			window.removeEventListener("resize",renderAppCssBreakpoints); 
	    }
	},[]);

	return(
		<div className={styles.unselectable}>
			<div className={styles.mainFooter}>
				<div style={{width:"100%",paddingTop:"20px",paddingBottom:"20px",height:"33px"}}>
					<div style={{position:"absolute",width:`${widthOfTitle}`}}>
						
						<div style={{width:"100%"}}>
							<span style={{display:"inline",fontSize:`${smallFontSize}`,textAlign:"center",fontWeight:"bold"}}>REPOMEAN LABS</span>
						</div>
						<div style={{width:"100%"}}>
							<span style={{display:"inline",fontSize:`${smallFontSize}`,textAlign:"center"}}>Kathmandu, Nepal</span>
						</div>
					</div>

					<div style={{width:`${widthOfMenu}`,paddingTop:"12px",float:"right"}}>
						<RenderResponsiveFooterMenu showNavigationButtons={showNavigationButtons} smallFontSize={smallFontSize} menuSize={menuSize} listItemStartCounter={listItemStartCounter} listItemStopCounter={listItemStopCounter} />
					</div>
				</div>
				<div style={{width:"100%",overflow:"hidden",paddingBottom:`${footerPaddingBottom}`,height:"15px"}}>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${smallFontSize}`}}>Repomean © 2021</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${smallFontSize}`}}>Privacy</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${smallFontSize}`}}>Terms</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
				</div>
			</div>
		</div>
	);

}

// class ApplicationFooter extends React.Component{
// 	constructor(props){
// 		super(props);
// 		this.state={fontSize:"20px",widthOfTitle:20,widthOfMenu:20,showNavigationButtons:"none",listItemStartCounter:0,listItemStopCounter:0}
// 	}
// 	componentWillMount(){
// 		this.renderAppCssBreakpoints();
// 		window.addEventListener("resize",this.renderAppCssBreakpoints);
// 	}
// 	componentWillUnmount(){
//     	window.removeEventListener("resize",this.renderAppCssBreakpoints); 
// 	}
// 	shouldComponentUpdate(){
// 		return true
// 	}
// 	renderAppCssBreakpoints=()=>{
// 		var temporaryWidthOfTitle=0;
// 		var temporaryWidthOfMenu=0;
// 		if((window.innerWidth>='320')&&(window.innerWidth<='480')){
// 			temporaryWidthOfTitle=(parseInt((39/100)*(window.innerWidth))+"px")
// 			temporaryWidthOfMenu=(parseInt((61/100)*(window.innerWidth))+"px")
// 			this.setState({fontSize:"23px",smallFontSize:"12px",displayType:"mobile",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"half-quad-full",listItemStartCounter:1,listItemStopCounter:2});
// 			this.setState({showNavigationButtons:"block"});
// 		}else if((window.innerWidth>='481')&&(window.innerWidth<='570')){
// 	    	temporaryWidthOfTitle=(parseInt((33/100)*(window.innerWidth))+"px")
// 			temporaryWidthOfMenu=(parseInt((67/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"23px",smallFontSize:"15px",displayType:"tablet",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"quad-full",listItemStartCounter:1,listItemStopCounter:3})
// 	    	this.setState({showNavigationButtons:"block"});
// 	    }else if((window.innerWidth>='571')&&(window.innerWidth<='768')){
// 	    	temporaryWidthOfTitle=(parseInt((30/100)*(window.innerWidth))+"px")
// 			temporaryWidthOfMenu=(parseInt((70/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"23px",smallFontSize:"17px",displayType:"tablet",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"half-not-full",listItemStartCounter:1,listItemStopCounter:4})
// 	    	this.setState({showNavigationButtons:"block"});
// 	    }else if((window.innerWidth>='769')&&(window.innerWidth<='1024')){
// 	    	temporaryWidthOfTitle=(parseInt((29/100)*(window.innerWidth))+"px")
// 			temporaryWidthOfMenu=(parseInt((71/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"21px",smallFontSize:"20px",displayType:"small-desktop",footerPaddingBottom:"30px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"quad-not-full",listItemStartCounter:1,listItemStopCounter:5})
// 	    	this.setState({showNavigationButtons:"block"});
// 	    }else if((window.innerWidth>='1025')&&(window.innerWidth<='1200')){
// 	    	temporaryWidthOfTitle=(parseInt((28/100)*(window.innerWidth))+"px")
// 			temporaryWidthOfMenu=(parseInt((72/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"24px",smallFontSize:"22px",displayType:"medium-desktop",footerPaddingBottom:"37px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
// 	    	this.setState({showNavigationButtons:"none"});
// 	    }else if((window.innerWidth>='1201')&&((window.innerWidth<='1680'))){
// 	    	temporaryWidthOfTitle=(parseInt((25/100)*(window.innerWidth))+"px")
// 			temporaryWidthOfMenu=(parseInt((75/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"25px",smallFontSize:"24px",displayType:"large-desktop",footerPaddingBottom:"38px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
// 	    	this.setState({showNavigationButtons:"none"});
// 	    }else if((window.innerWidth>='1681')&&(window.innerWidth<='2201')){
// 	    	temporaryWidthOfTitle=(parseInt((25/100)*(window.innerWidth))+"px")
// 			temporaryWidthOfMenu=(parseInt((75/100)*(window.innerWidth))+"px")
// 	    	this.setState({fontSize:"25px",smallFontSize:"24px",displayType:"large-desktop",footerPaddingBottom:"39px",widthOfTitle:temporaryWidthOfTitle,widthOfMenu:temporaryWidthOfMenu,menuSize:"full",listItemStartCounter:1,listItemStopCounter:7})
// 	    	this.setState({showNavigationButtons:"none"});
// 	    }else{}
// 	};
// 	render(){
// 		return(
// 			<div className={styles.unselectable}>
// 				<div className={styles.mainFooter}>
// 					<div style={{width:"100%",paddingTop:"20px",paddingBottom:"20px",height:"33px"}}>
// 						<div style={{position:"absolute",width:`${this.state.widthOfTitle}`}}>
							
// 							<div style={{width:"100%"}}>
// 								<span style={{display:"inline",fontSize:`${this.state.smallFontSize}`,textAlign:"center",fontWeight:"bold"}}>REPOMEAN LABS</span>
// 							</div>
// 							<div style={{width:"100%"}}>
// 								<span style={{display:"inline",fontSize:`${this.state.smallFontSize}`,textAlign:"center"}}>Kathmandu, Nepal</span>
// 							</div>
// 						</div>

// 						<div style={{width:`${this.state.widthOfMenu}`,paddingTop:"12px",float:"right"}}>
// 							<RenderResponsiveFooterMenu showNavigationButtons={this.state.showNavigationButtons} smallFontSize={this.state.smallFontSize} menuSize={this.state.menuSize} listItemStartCounter={this.state.listItemStartCounter} listItemStopCounter={this.state.listItemStopCounter} />
// 						</div>
// 					</div>
// 					<div style={{width:"100%",overflow:"hidden",paddingBottom:`${this.state.footerPaddingBottom}`,height:"15px"}}>
// 						<div style={{float:"right"}}>
// 							<span style={{display:"inline",fontSize:`${this.state.smallFontSize}`}}>Repomean © 2021</span>
// 						</div>
// 						<ul style={{float:"right"}}>
// 							<span style={{display:"inline"}}></span>
// 						</ul>
// 						<div style={{float:"right"}}>
// 							<span style={{display:"inline",fontSize:`${this.state.smallFontSize}`}}>Privacy</span>
// 						</div>
// 						<ul style={{float:"right"}}>
// 							<span style={{display:"inline"}}></span>
// 						</ul>
// 						<div style={{float:"right"}}>
// 							<span style={{display:"inline",fontSize:`${this.state.smallFontSize}`}}>Terms</span>
// 						</div>
// 						<ul style={{float:"right"}}>
// 							<span style={{display:"inline"}}></span>
// 						</ul>
// 					</div>
// 				</div>
// 			</div>
// 		);
// 	}
// }

function RenderResponsiveFooterMenu({showNavigationButtons,smallFontSize,menuSize,listItemStartCounter,listItemStopCounter}){
	const[_showNavigationButtons,setShowNavigationButtons]=useState(showNavigationButtons);
	const[_smallFontSize,setSmallFontSize]=useState(smallFontSize);
	const[_menuSize,setMenuSize]=useState(menuSize);
	const[_listItemStartCounter,setListItemStartCounter]=useState(listItemStartCounter);
	const[_listItemStopCounter,setListItemStopCounter]=useState(listItemStopCounter);

	function clickedRight(){
		if(_listItemStopCounter>=7){}else{
			setListItemStartCounter(_listItemStartCounter+1);
			setListItemStopCounter(_listItemStopCounter+1);
			//this.setState({listItemStartCounter:this.state.listItemStartCounter+1,listItemStopCounter:this.state.listItemStopCounter+1});
		}
		//console.log("clicked right and start counter is "+this.state.listItemStartCounter+" and stop counter is "+this.state.listItemStopCounter);
	}
	function clickedLeft(){
		if(_listItemStartCounter<=1){}else{
			// this.setState({listItemStartCounter:this.state.listItemStartCounter-1,listItemStopCounter:this.state.listItemStopCounter-1});
			setListItemStartCounter(_listItemStartCounter-1);
			setListItemStopCounter(_listItemStopCounter-1);
		}
		//console.log("clicked left and start counter is "+this.state.listItemStartCounter+" and stop counter is "+this.state.listItemStopCounter);
	}
	useEffect(()=>{
		if ((_listItemStopCounter !== listItemStopCounter)||(_listItemStartCounter !== listItemStartCounter)) {
		  setListItemStartCounter(listItemStartCounter);
		  setListItemStopCounter(listItemStopCounter);
		  setSmallFontSize(smallFontSize);
		  // this.setState({listItemStopCounter:this.props.listItemStopCounter,listItemStartCounter:this.props.listItemStartCounter,smallFontSize:this.props.smallFontSize})
		}
	},[listItemStartCounter,listItemStopCounter]);
	return(
		<div>
			<div style={{float:"right"}}>
				<span style={{display:"inline",fontSize:`${_smallFontSize}`,display:`${showNavigationButtons}`,paddingRight:"20px"}}>&nbsp;</span>
			</div>
			<div style={{float:"right"}}>
				<span style={{display:"inline",fontSize:`${_smallFontSize}`,display:`${showNavigationButtons}`}} onClick={clickedRight}>➡</span>
			</div>
				<RenderMenuDemo showNavigationButtons={showNavigationButtons} smallFontSize={smallFontSize} menuSize={menuSize} listItemStartCounter={_listItemStartCounter} listItemStopCounter={_listItemStopCounter}/>
			<div style={{float:"right"}}>
				<span style={{display:"inline",fontSize:`${_smallFontSize}`,display:`${showNavigationButtons}`}} onClick={clickedLeft}>⬅</span>
			</div>
		</div>
	);
}

// class RenderResponsiveFooterMenu extends React.Component{
// 	constructor(props){
// 		super(props);
// 		this.state={showNavigationButtons:props.showNavigationButtons,smallFontSize:props.smallFontSize,menuSize:props.menuSize,listItemStartCounter:props.listItemStartCounter,listItemStopCounter:props.listItemStopCounter}
// 		console.log("start count is "+props.listItemStartCounter)
// 		console.log("stop count is "+props.listItemStopCounter)
// 		console.log("font size is"+props.smallFontSize)
// 	}
// 	clickedRight=()=>{
		
// 		if(this.state.listItemStopCounter>=7){}else{
// 			this.setState({listItemStartCounter:this.state.listItemStartCounter+1,listItemStopCounter:this.state.listItemStopCounter+1});
// 		}
// 		//console.log("clicked right and start counter is "+this.state.listItemStartCounter+" and stop counter is "+this.state.listItemStopCounter);

// 	}
// 	clickedLeft=()=>{
		
// 		if(this.state.listItemStartCounter<=1){}else{
// 			this.setState({listItemStartCounter:this.state.listItemStartCounter-1,listItemStopCounter:this.state.listItemStopCounter-1});
// 		}
// 		//console.log("clicked left and start counter is "+this.state.listItemStartCounter+" and stop counter is "+this.state.listItemStopCounter);

// 	}
// 	componentDidUpdate(prevProps) {
// 	  if ((prevProps.listItemStopCounter !== this.props.listItemStopCounter)||(prevProps.listItemStartCounter !== this.props.listItemStartCounter)) {
// 	  	this.setState({listItemStopCounter:this.props.listItemStopCounter,listItemStartCounter:this.props.listItemStartCounter,smallFontSize:this.props.smallFontSize})
// 	  }
// 	}
	
// 	shouldComponentUpdate(){
// 		return true
// 	}
// 	render(){
// 		return(
// 			<div>
// 				<div style={{float:"right"}}>
// 					<span style={{display:"inline",fontSize:`${this.state.smallFontSize}`,display:`${this.props.showNavigationButtons}`,paddingRight:"20px"}}>&nbsp;</span>
// 				</div>
// 				<div style={{float:"right"}}>
// 					<span style={{display:"inline",fontSize:`${this.state.smallFontSize}`,display:`${this.props.showNavigationButtons}`}} onClick={this.clickedRight}>➡</span>
// 				</div>
// 					<RenderMenuDemo showNavigationButtons={this.props.showNavigationButtons} smallFontSize={this.props.smallFontSize} menuSize={this.props.menuSize} listItemStartCounter={this.state.listItemStartCounter} listItemStopCounter={this.state.listItemStopCounter}/>
// 				<div style={{float:"right"}}>
// 					<span style={{display:"inline",fontSize:`${this.state.smallFontSize}`,display:`${this.props.showNavigationButtons}`}} onClick={this.clickedLeft}>⬅</span>
// 				</div>
// 			</div>
// 		);
// 	}
// }


function RenderMenuDemo(props){
	
	console.log(props.listItemStartCounter)
	console.log(props.listItemStopCounter)
	
	if((props.listItemStartCounter==1)&&(props.listItemStopCounter==2)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Home</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==2)&&(props.listItemStopCounter==3)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==3)&&(props.listItemStopCounter==4)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==4)&&(props.listItemStopCounter==5)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==5)&&(props.listItemStopCounter==6)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Contacts</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==6)&&(props.listItemStopCounter==7)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==1)&&(props.listItemStopCounter==3)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Home</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==2)&&(props.listItemStopCounter==4)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==3)&&(props.listItemStopCounter==5)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==4)&&(props.listItemStopCounter==6)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Contacts</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==5)&&(props.listItemStopCounter==7)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>About</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Contacts</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==1)&&(props.listItemStopCounter==4)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Home</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==2)&&(props.listItemStopCounter==5)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==3)&&(props.listItemStopCounter==6)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Contacts</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==4)&&(props.listItemStopCounter==7)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>About</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Contacts</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==1)&&(props.listItemStopCounter==5)){
		console.log("even came here");
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Home</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==2)&&(props.listItemStopCounter==6)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Contacts</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==3)&&(props.listItemStopCounter==7)){
		return(
			<div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>About</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Contacts</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
				<div style={{float:"right"}}>
					<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
				</div>
				<ul style={{float:"right"}}>
					<span style={{display:"inline"}}></span>
				</ul>
			</div>
		);
	}else if((props.listItemStartCounter==1)&&(props.listItemStopCounter==7)){
		console.log("even came here");
		return(
			<div>
				<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>About</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Contacts</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Career</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>News</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Services</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
					<div style={{float:"right"}}>
						<span style={{display:"inline",fontSize:`${props.smallFontSize}`}}>Home</span>
					</div>
					<ul style={{float:"right"}}>
						<span style={{display:"inline"}}></span>
					</ul>
			</div>
		);
	}else{
		return(<div></div>);
	}
}



export default ApplicationFooter