import React from 'react';
import { useState,useEffect } from 'react';
import ComputerCode from './computerCode.png';
import PrototypeDevelopment from './prototype.png';
import PriceQuotation from './price-quotation.jpg';
import ImageLogo from './repomeanLogo.jpg';
import HomeApplicationBody from './ApplicationBody/HomeApplicationBody';
import ServicesApplicationBody from './ApplicationBody/ServicesApplicationBody';
import NewsApplicationBody from './ApplicationBody/NewsApplicationBody';
import CareerApplicationBody from './ApplicationBody/CareerApplicationBody';
import AboutApplicationBody from './ApplicationBody/AboutApplicationBody';
import ContactsApplicationBody from './ApplicationBody/ContactsApplicationBody';

function ApplicationBody({HomeApplicationBody, mobileApp, marginTopOfBody,currentPage, updateCurrentPage, topOfThePage}){
	const[currentPageState,setCurrentPageState]=useState(currentPage);
	const[marginTop,setMarginTop]=useState(marginTopOfBody);
	const[mobileAppState,setMobileAppState]=useState(mobileApp);
	function constructorFunction(){
		if((topOfThePage)&&(!mobileApp)){
			setMarginTop("60px");
			//this.setState({marginTop:"60px"})
		}else if(mobileApp){
			console.log("came here");
			// this.setState({marginTop:"60px"})
			setMarginTop("60px");
			//console.log(this.state.marginTop)
		}else{
			setMarginTop("100px");
			//this.setState({marginTop:"100px"})
		}
	}
	useEffect(()=>{
		constructorFunction();
	},[]);
	useEffect(()=>{
		constructorFunction();
	},[topOfThePage]);
	return(
		<div style={{marginTop:`${marginTop}`}}>
			<RenderConditionalBodyComponents HomeApplicationBody={HomeApplicationBody} currentPage={currentPage} />
		</div>
	);
}

function RenderConditionalBodyComponents(props){
	if(props.currentPage=="HOME"){
		return <HomeApplicationBody HomeApplicationBody={props.HomeApplicationBody} />;
	}else if(props.currentPage=="SERVICES"){
		return <ServicesApplicationBody />;
	}else if(props.currentPage=="NEWS"){
		return <NewsApplicationBody />;
	}else if(props.currentPage=="CAREER"){
		return <CareerApplicationBody />;
	}else if(props.currentPage=="CONTACTS"){
		return <ContactsApplicationBody />;
	}else if(props.currentPage=="ABOUT"){
		return <AboutApplicationBody />;
	}
}
export default ApplicationBody
